import { ApiClient, DefaultApiParams } from './axiosClient';
import { ProductFilterPayload } from '@interfaces';

export const productAPI = {
  getProducts: (params: DefaultApiParams) => {
    const url = '/products';
    return ApiClient.get(url, { params });
  },
  getAttributes: () => {
    const url = `/attributes`;
    return ApiClient.get(url);
  },
  createAttribute: (payload: { name: string }) => {
    const url = `/attributes`;
    return ApiClient.post(url, { ...payload, detail: true });
  },
  uploadImages: (payload: FormData) => {
    const url = `/images`;
    return ApiClient.post(url, payload);
  },
  createProduct: (payload: any) => {
    const url = `/products`;
    return ApiClient.post(url, payload);
  },
  getProduct: (id: string) => {
    const url = `/products/${id}`;
    return ApiClient.get(url);
  },
  updateProduct: (id: string, payload: any) => {
    const url = `/products/${id}`;
    return ApiClient.put(url, payload);
  },
  getProductByFilter: (page: number, payload?: ProductFilterPayload) => {
    const url = `/products/filter/client?page=${page}`;
    return ApiClient.post(url, payload);
  },
  updateProductStatus: (status: boolean, id: string) => {
    const url = `/admin/products/${id}?active=${status}`;
    return ApiClient.put(url);
  },
  deleteProduct: (id: string) => {
    const url = `/admin/products/${id}`;
    return ApiClient.delete(url);
  },
};
