import {
  AppModal,
  Pagination,
  SearchIcon,
  SharedButton,
  SharedTable,
  ShareInput,
} from '@components';
import { MAIN_THEME_DATA } from '@configs';
import { BannerColumn, BannerData } from '@interfaces';
import { UploadImageModule } from '@modules';
import { themes, useTheme } from '@theme';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';
interface IProps {
  bannerData: BannerData;
  uploadImageRef: any;
  uploadBanners: () => Promise<void>;
  onModalInteract: (value: any) => void;
  changeSelectedImage: (value: any) => void;
  columns: ColumnsType<BannerColumn>;
  currentPage: number;
  modalData: any;
  onPageChange: (page: number) => void;
}

export const BannerList = ({
  bannerData,
  uploadImageRef,
  uploadBanners,
  changeSelectedImage,
  columns,
  currentPage,
  onModalInteract,
  modalData,
  onPageChange,
}: IProps) => {
  const { theme } = useTheme();
  return (
    <StyledTableWrapper>
      <StyledSearchWrapper>
        <div className="input-wrapper">
          <ShareInput
            containerClassName="search-tier__box"
            className="search-tier__input"
            onChange={(e: any) => {}}
            placeholder="Tìm kiếm"
            prevIcon={<SearchIcon />}
          />
        </div>
        <div className="flex">
          <SharedButton
            onClick={() => {
              onModalInteract({ visible: true, image: undefined });
            }}
            className="create-tier__button"
            backgroundColor={MAIN_THEME_DATA.mainColor}
            text="Thêm banner"
            btnStyle="pad"
          />
        </div>
      </StyledSearchWrapper>
      <SharedTable
        dataSource={bannerData.data}
        columns={columns} // key={String(isLevelChanging)}
        // rowKey={String(isLevelChanging)}
      />
      <div className="bottom-pagination">
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={bannerData.count}
          pageSize={bannerData.limit}
          onPageChange={(page: number) => {
            onPageChange(page);
          }}
        />
      </div>
      <AppModal open={modalData.visible} onClose={() => {}}>
        <div className="modal">
          <h2>Thêm ảnh</h2>
          <UploadImageModule
            ref={uploadImageRef}
            onUploadEnd={(v) => {
              changeSelectedImage(v);
            }}
            typeUpload="image"
          />
          <div className="button-wrapper">
            <SharedButton
              onClick={() => {
                onModalInteract({ image: undefined, visible: false });
              }}
              typeHtml="button"
              className="btn-save btn-cancel"
              backgroundColor="transparent"
              textColor={theme?.colors?.button?.text || themes.theme.light.colors.button.text}
              borderColor={theme?.colors?.button?.border || themes.theme.light.colors.button.border}
              text={'Đóng'}
            />
            <SharedButton
              onClick={() => {
                uploadBanners();
              }}
              className="btn-save"
              textColor="white"
              backgroundColor={MAIN_THEME_DATA.mainColor}
              text={'Lưu'}
            />
          </div>
        </div>
      </AppModal>
    </StyledTableWrapper>
  );
};
const StyledTableWrapper = styled.div`
  .modal {
    width: 30rem !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    img {
      object-fit: contain;
    }
  }
  .upload-btn {
    background-color: ${MAIN_THEME_DATA.mainColor} !important;
    height: 4.5rem;
    width: fit-content;
  }
  .contain-upload {
    margin-bottom: 0;
  }
  .btn-action {
    align-self: center;
    width: fit-content;
    padding: 0.8rem 1.8rem;
  }
  .sub-category-block {
    margin-top: 1rem;
    .title {
      display: inline-block;
      margin-bottom: 0.8rem;
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 20px;
      color: #303030;
    }
    .input-wrapper {
      margin-bottom: 1rem;
    }
  }
  .add-sub-category-btn {
    cursor: pointer;
    height: 4rem;
    border: 1px dashed ${MAIN_THEME_DATA.mainColor};
    width: 100%;
    border-radius: 0.6rem;
    margin-top: 1.5rem;
    .text-block {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      span {
        font-weight: bold;
        margin-left: 1rem;
        font-size: larger;
        color: ${MAIN_THEME_DATA.mainColor};
      }
    }
  }
  .bottom-pagination {
    margin-top: 3.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .page-size {
      display: flex;
      align-items: center;
      .label {
        display: inline-block;
        white-space: nowrap;
        padding-right: 0.8rem;
      }
    }
  }
  .level {
    display: flex;
    align-items: center;
    justify-content: center;
    .level-change {
      /* margin-right: 3rem; */
      .btn {
        box-shadow: none;
      }
    }
  }
  .modal {
    width: 100rem;
    @media (max-width: 1280px) {
      width: 70rem;
    }
    @media (max-width: 820px) {
      width: 50rem;
    }
    h2 {
      text-align: center;
      margin-bottom: 1rem;
    }
    .button-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
      .btn-save {
        align-self: center;
        width: fit-content;
        padding: 1rem 2rem;
      }
      .btn-cancel {
        margin-right: 1rem;
      }
    }
    .create-tier__button {
      height: 4.5rem;
      width: 12.8rem;
      margin-top: 1rem;
    }
  }
`;
const StyledSearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 2rem;
  .search-tier__box {
    min-width: 20rem;
    width: fit-content;
    .search-tier__input {
      height: 4.5rem;
    }
  }
  .create-tier__button {
    height: 4.5rem;
    width: fit-content;
  }
  /* .input-wrapper {
    width: 30%;
    margin-right: 2rem;
  }
  .text-button {
    padding: 0.6rem 1.2rem;
  } */
`;
