import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DefaultApiParams, memberAPI } from '@api';
import { CustomerListSection } from '@components';
import { INITIAL_PAGE, MAX_ITEM_PER_PAGE } from '@configs';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AccountStatus,
  AutocompleteItem,
  Customer,
  CustomerColumn,
  CustomerData,
  CustomerLevel,
  CustomerRoot,
  FilterDataItem,
  RootResponse,
  UpdateAccountStatusPayload,
  UpdateCustomerPayload,
} from '@interfaces';
import {
  selectApp,
  selectAuth,
  setCurrentPage,
  setLoading,
  setTableLoading,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { LogApp, convertPriceToInt } from '@utils';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useImmer } from 'use-immer';
import * as yup from 'yup';
const rejectedSchema = yup.object().shape({
  reason: yup.string().required('Vui lòng nhập lí do'),
});
const acceptedSchema = yup.object().shape({
  code: yup.string().required('Vui lòng nhập mã khách hàng'),
});
const DEFAULT_MODAL_DATA = {
  opened: false,
  currentId: '',
};
const DEFAULT_EXTRA_INPUT = {
  status: AccountStatus.ACCEPTED,
  shown: false,
};
export const CustomersModule = () => {
  const [showExtraInput, setShowExtraInput] = useImmer(DEFAULT_EXTRA_INPUT);
  const {
    register,
    handleSubmit,
    reset,
    setError,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      showExtraInput.status === AccountStatus.ACCEPTED ? acceptedSchema : rejectedSchema,
    ),
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { role } = useAppSelector(selectAuth);
  const { themeMode, currentPage } = useAppSelector(selectApp);
  const [tierFilterData, setTierFilterData] = useState<FilterDataItem[]>([]);
  const [modalData, setModalData] = useImmer(DEFAULT_MODAL_DATA);
  const [members, setMembers] = useImmer<CustomerData>({
    limit: 0,
    count: 0,
    data: [],
  });
  const [filters, setFilters] = useImmer<{
    customerLevels: AutocompleteItem<CustomerLevel>[];
  }>({
    customerLevels: [],
  });
  const [payload, setPayload] = useImmer<DefaultApiParams>({
    page: currentPage || 0,
    customer_type_id: null,
    keyword: null,
  });
  const onSearch = debounce((value: string) => {
    setPayload({ ...payload, keyword: value === '' ? null : value.trim(), page: INITIAL_PAGE });
  }, 500);
  const onChangeAccountStatus = async (id: string, payload: UpdateAccountStatusPayload) => {
    try {
      dispatch(setLoading(true));
      const res = await memberAPI.changeAccountStatus(id, payload);
      await getMembers();
      toast.success('Thành công', {
        position: 'top-right',
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'light',
      });
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
  const onViewProfile = (item: Customer) => {
    const { image, full_name, phone_number, address, seller, status, customer_level, dob } = item;
    reset({
      avatar: image?.url,
      full_name,
      phone_number,
      province: address?.provinces.province_name,
      district: address?.districts.district_name,
      ward: address?.wards.ward_name,
      address_detail: address?.detail,
      seller_name: seller.brand_name,
      status,
      reason: '',
      code: '',
      image_id: image?.id,
      dob,
      seller_id: seller.id,
    });
    setValue('customer_level', {
      value: item.customer_level.name,
      label: item.customer_level.name,
      item: item.customer_level,
    });
    setModalData((draft) => {
      draft.currentId = item.id;
      draft.opened = true;
    });
    setShowExtraInput(DEFAULT_EXTRA_INPUT);
  };
  const onModalInteract = (value: boolean) => {
    setModalData((draft) => {
      draft.opened = value;
    });
  };
  const onClose = () => {
    setModalData(DEFAULT_MODAL_DATA);
    setShowExtraInput((draft) => {
      draft.shown = false;
    });
  };
  const getMembers = async () => {
    try {
      dispatch(setTableLoading(true));
      const res: CustomerRoot = await memberAPI.getCustomers({
        ...payload,
        keyword: payload?.keyword?.trim(),
      });
      const members = res.data?.customers;
      const memberList: CustomerColumn[] = members?.map((item, index) => {
        return {
          no: index + 1,
          key: item.id,
          full_name: item.full_name,
          phone_number: item.phone_number,
          seller_name: item.seller.brand_name,
          email: item.email,
          level: item.customer_level.name,
          dob: item.dob,
          status: item.status,
          code: item?.code || '',
          bonus_coin: item.bonus_coin,
          onEdit: () => {
            onViewProfile(item);
          },
          onChangeStatus: () => {
            onChangeAccountStatus(item.id, {
              status:
                item.status === AccountStatus.ACCEPTED
                  ? AccountStatus.LOCKED
                  : AccountStatus.ACCEPTED,
              code: item.code,
            });
          },
        };
      });
      setMembers({
        limit: MAX_ITEM_PER_PAGE,
        count: res.data.count,
        data: memberList,
      });
    } catch (error) {
    } finally {
      dispatch(setTableLoading(false));
    }
  };

  const handleChangePage = (page: number) => {
    setPayload({ ...payload, page });
    dispatch(setCurrentPage(Number(page)));
  };
  const onDeny = async () => {
    if (getValues('status') === AccountStatus.ACCEPTED) {
      await onChangeAccountStatus(modalData.currentId, { status: AccountStatus.LOCKED });
      onModalInteract(false);
    } else
      setShowExtraInput((draft) => {
        draft.shown = true;
        draft.status = AccountStatus.REJECTED;
      });
  };
  const onAccept = async () => {
    if (getValues('status') === AccountStatus.LOCKED) {
      await onChangeAccountStatus(modalData.currentId, { status: AccountStatus.ACCEPTED });
      onModalInteract(false);
    } else
      setShowExtraInput((draft) => {
        draft.shown = true;
        draft.status = AccountStatus.ACCEPTED;
      });
  };
  const getValidMoneyBeginningPeriod = () => {
    if (
      !getValues('total_money_debt') &&
      !getValues('total_money_revenue') &&
      !getValues('total_money_reward')
    )
      return null;
    else
      return {
        total_money_debt: isValidValue('total_money_debt'),
        total_money_revenue: isValidValue('total_money_revenue'),
        total_money_reward: isValidValue('total_money_reward'),
      };
  };
  const onSubmit = handleSubmit(async (data) => {
    const { reason, code } = data;
    const rejectedPayload: UpdateAccountStatusPayload = {
      status: AccountStatus.REJECTED,
      reason,
      code: null,
      money_beginning_period: null,
    };
    const acceptedPayload: UpdateAccountStatusPayload = {
      status: AccountStatus.ACCEPTED,
      reason: null,
      code,
      money_beginning_period: getValidMoneyBeginningPeriod(),
    };
    try {
      dispatch(setLoading(true));
      await onChangeAccountStatus(
        modalData.currentId,
        showExtraInput.status === AccountStatus.ACCEPTED ? acceptedPayload : rejectedPayload,
      );
      // onModalInteract(false);
      toast.success('Thành công', {
        position: 'top-right',
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'light',
      });
      await getMembers();
    } catch (err: any) {
      LogApp('catch');
    } finally {
      dispatch(setLoading(false));
    }
  });
  const getDropdownData = async () => {
    try {
      const customerLevels: RootResponse<CustomerLevel[]> = await memberAPI.getCustomerLevels();
      setFilters((draft) => {
        draft.customerLevels = customerLevels.data.map((item) => {
          return {
            label: item.name,
            value: item.id,
            item,
          };
        });
      });
    } catch (error) {
      LogApp(error, 'err');
    }
  };
  const onSelectCustomerType = (value: AutocompleteItem<CustomerLevel>) => {
    setPayload((d) => {
      if (value.value) d.customer_type_id = value.item.id;
      else d.customer_type_id = value.value;
    });
  };
  const updateCustomerInfo = async () => {
    if (!getValues('customer_level')) {
      setError('customer_level', { message: 'Vui lòng chọn nhóm khách hàng' });
      return;
    }
    try {
      dispatch(setLoading(true));
      const customerLevel = getValues('customer_level') as AutocompleteItem<CustomerLevel>;
      const payload: UpdateCustomerPayload = {
        customer_type_id: customerLevel.item.id,
        seller_id: getValues('seller_id'),
        full_name: getValues('full_name'),
        phone_number: getValues('phone_number'),
        dob: getValues('dob'),
        image_id: getValues('image_id'),
      };
      const res = await memberAPI.updateCustomer(modalData.currentId, payload);
      await getMembers();
      // onModalInteract(false);
      toast.success('Thành công', {
        position: 'top-right',
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'light',
      });
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
  const isValidValue = (key: string) => {
    if (getValues(key)) return convertPriceToInt(getValues(key));
    return 0;
  };
  const updateMoneyBeginningPeriod = async () => {
    try {
      dispatch(setLoading(true));
      const payload: UpdateAccountStatusPayload = {
        money_beginning_period: {
          total_money_debt: isValidValue('total_money_debt'),
          total_money_revenue: isValidValue('total_money_revenue'),
          total_money_reward: isValidValue('total_money_reward'),
        },
      };
      const res = await memberAPI.changeAccountStatus(modalData.currentId, payload);
      await getMembers();
      toast.success('Thành công', {
        position: 'top-right',
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'light',
      });
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
  const onChangeCustomerType = (v: AutocompleteItem<CustomerLevel>) => {
    if (v.value) setValue('customer_level', v);
    else setValue('customer_level', '');
  };
  useEffect(() => {
    getMembers();
  }, [payload]);
  useEffect(() => {
    getDropdownData();
  }, []);
  return (
    <CustomerListSection
      onDeny={onDeny}
      onAccept={onAccept}
      register={register}
      errors={errors}
      getValues={getValues}
      handleSubmit={onSubmit}
      onSearch={onSearch}
      memberData={members}
      tierFilterData={tierFilterData}
      currentPage={payload?.page || 0}
      onPageChange={handleChangePage}
      modalData={modalData}
      onClose={onClose}
      showExtraInput={showExtraInput}
      filters={filters}
      onSelectCustomerType={onSelectCustomerType}
      onChangeCustomerType={onChangeCustomerType}
      updateCustomerInfo={updateCustomerInfo}
      updateMoneyBeginningPeriod={updateMoneyBeginningPeriod}
      control={control}
    />
  );
};
