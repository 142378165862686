import { MAIN_THEME_DATA } from '@configs';
import { Feature } from '@interfaces';
import { opacityHex } from '@theme';
import { LogApp } from '@utils';
import { AutoComplete } from 'antd';
import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
interface ISharedAutocompleteV2 {
  data: any;
  label?: string;
  className?: string;
  containerClassName?: string;
  placeholder?: string;
  value?: any;
  onSelect: (value: any) => void;
  onTextChange?: (value: string) => void;
  defaultValue?: any[];
  required?: boolean;
  isMulti?: boolean;
  errors?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  name?: string;
  onDataChange?: (count: number) => void;
  isClearable?: boolean;
  selectRef?: any;
}
export const SharedAutocompleteV2 = ({
  data,
  label,
  className,
  containerClassName,
  placeholder,
  onSelect,
  defaultValue,
  required = false,
  errors,
  isMulti = false,
  name,
  onTextChange,
  onDataChange,
  value,
  isClearable = false,
  selectRef,
}: ISharedAutocompleteV2) => {
  const customFilterOption = (option: FilterOptionOption<any>, rawInput: string) => {
    const words = rawInput.split(' ');
    return words.reduce((acc, cur) => {
      return acc && option.label.toLowerCase().includes(cur.toLowerCase());
    }, true);
  };
  return (
    <StyledAutocomplete
      className={containerClassName ? `${containerClassName} shared-input` : 'shared-input'}
    >
      {label && (
        <label className="input__label" htmlFor={label}>
          {label}:{required && <span className="required"> *</span>}
        </label>
      )}
      <Select
        ref={selectRef}
        value={value}
        isClearable={isClearable}
        onInputChange={(text) => {
          onTextChange?.(text);
        }}
        onChange={(v, context) => {
          if (context.action === 'select-option') onSelect(v);
          else if (context.action === 'clear') onSelect({ value: null });
        }}
        defaultValue={defaultValue}
        isMulti={isMulti}
        name={name}
        options={data}
        placeholder={placeholder}
        className={isMulti ? 'basic-multi-select' : 'basic-single'}
        classNamePrefix="select"
        // filterOption={(opt, input) => {
        //   return customFilterOption(opt, input);
        // }}
      />
      {!!errors && <p className="input-text-error">{errors}</p>}
    </StyledAutocomplete>
  );
};
const StyledAutocomplete = styled.div`
  width: 100%;
  .basic-multi-select {
    width: 100%;
    height: 4rem;
  }
  label {
    display: inline-block;
    margin-bottom: 0.8rem;
    color: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 20px;
    text-align: center;
    color: #303030;
    .required {
      color: #d42a1c;
      font-weight: bold;
    }
  }
`;
