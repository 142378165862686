import { Minus, Plus } from 'src/components/Icon';
import { styled } from 'styled-components';

interface QuantityControlProps {
  quantity: number;
  size?: 'small' | 'big';
  hasTitle?: boolean;
  disabled?: boolean;
  onIncrease: () => void;
  onDecrease: () => void;
  onChangeQuantity: (value: number) => void;
}
export const QuantityControl = (props: QuantityControlProps) => {
  const {
    quantity,
    size = 'big',
    hasTitle = true,
    onIncrease,
    onDecrease,
    onChangeQuantity,
    disabled = false,
  } = props;
  const iconSize = size === 'big' ? 20 : 12;
  return (
    <StyledQuantityControlWrapper>
      {hasTitle && <span className="title">Số lượng:</span>}
      <StyledQuantityControl disabled={disabled} size={size}>
        <div
          onClick={() => {
            if (!disabled) onDecrease();
          }}
          className="plus-block"
        >
          <Minus strokeWidth={2} width={iconSize} height={iconSize} />
        </div>
        <input
          disabled={disabled}
          onChange={(e) => {
            onChangeQuantity(Number(e.target.value));
          }}
          type="text"
          pattern="\d*"
          maxLength={3}
          value={quantity}
          className="quantity"
        />
        <div
          onClick={() => {
            if (!disabled) onIncrease();
          }}
          className="minus-block"
        >
          <Plus strokeWidth={2} width={iconSize} height={iconSize} />
        </div>
      </StyledQuantityControl>
    </StyledQuantityControlWrapper>
  );
};
const StyledQuantityControlWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;
const StyledQuantityControl = styled.div<{ size?: 'small' | 'big'; disabled?: boolean }>`
  margin-left: 1rem;
  background-color: ${(p) => (p?.disabled ? '#C4C4C4' : 'white')};
  width: ${(p) => (p?.size === 'small' ? '10rem' : '20rem')};
  border: ${(p) => (p?.size === 'small' ? '1px solid #888' : 'unset')};
  box-shadow: ${(p) => (p?.size === 'small' ? 'unset' : '0px 1px 16px rgba(56, 56, 56, 0.15)')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${(p) => (p?.size === 'small' ? '0.8rem' : '1.2rem')};
  margin-top: 1.2rem;
  input[type='text']:disabled {
    background-color: #c4c4c4;
  }
  .quantity {
    color: #383838;
    font-size: ${(p) => (p?.size === 'small' ? '1.7rem' : '2.4rem')};
    line-height: ${(p) => (p?.size === 'small' ? '23.8px' : '33.6px')};
    font-weight: 500;
    width: 3rem;
    text-align: center;
    outline: unset;
  }
  .plus-block {
    cursor: pointer;
    border-right-width: 1px;
    padding: ${(p) => (p?.size === 'small' ? '0.8rem 0.6rem' : '1.6rem')};
  }
  .minus-block {
    cursor: pointer;
    border-left-width: 1px;
    padding: ${(p) => (p?.size === 'small' ? '0.8rem 0.6rem' : '1.6rem')};
  }
`;
