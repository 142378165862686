import { DefaultApiParams } from '@api';
import { IMAGES } from '@assets';
import { DEFAULT_CATEGORY_FILTER_ITEM, MAIN_THEME_DATA, MAT_SM_SCREEN_WIDTH_MIN } from '@configs';
import {
  Classification,
  Order,
  OrderItem,
  Product,
  ProductFilterDropdowns,
  ProductResponse,
  SubClassification,
} from '@interfaces';
import { CustomTooltip } from '@modules';
import { themes, useTheme } from '@theme';
import { toVND } from '@utils';
import { Col, Collapse, Divider, List, Rate, Row, Skeleton } from 'antd';
import {
  Control,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import { styled } from 'styled-components';
import { AppModal } from '../AppModal';
import { IConCancel, PlusIcon, SearchIcon } from '../Icon';
import { ShareInput, ShareSelectInput, SharedButton, SharedCheckbox, SharedImage } from '../shared';
import { CustomerInfo } from './components/CustomerInfo';
import { OrderInfo } from './components/OrderInfo';
import { ProductItemList } from './components/ProductItemList';
import { QuantityControl } from './components/QuantityControl';
import { SellerInfo } from './components/SellerInfo';
const { Panel } = Collapse;
interface OrderDetailSectionProps {
  order: Order;
  control: Control<any, any>;
  watch: UseFormWatch<any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  onIncrease: (item: OrderItem) => void;
  onDecrease: (item: OrderItem) => void;
  onChangeQuantity: (item: OrderItem, value: number) => void;
  onDeleteOrderItem: (item: OrderItem) => void;
  onModalInteract: (value: boolean) => void;
  opened: boolean;
  productData: ProductResponse;
  loadMoreData: () => void;
  onClose: () => void;
  onSelectClassification: (item: Product, classification: Classification) => void;
  onSelectSubClassification: (item: Product, classification: SubClassification) => void;
  getTooltipMessage: (item: Product) => string;
  disableCheckbox: (item: Product) => boolean;
  onIncreaseProducts: (item: Product) => void;
  onDecreaseProducts: (item: Product) => void;
  onSelectProduct: (item: Product) => void;
  onChangeQuantityProducts: (item: Product, value: number) => void;
  onConfirm: () => void;
  onSubmit: () => void;
  productFilterData: ProductFilterDropdowns;
  onSearch: (text: string) => void;
  onChangeWarehouse: (id: string) => void;
  onChangeCategory: (id: string) => void;
  openProductModal: () => void;
  goBack: () => void;
  updateAddon: () => void;
  payload: DefaultApiParams;
  syncOrder: () => Promise<void>;
}
export const OrderDetailSection = (props: OrderDetailSectionProps) => {
  const {
    control,
    register,
    setValue,
    getValues,
    order,
    onIncrease,
    onDecrease,
    onChangeQuantity,
    onModalInteract,
    opened,
    productData,
    loadMoreData,
    onSelectClassification,
    onSelectSubClassification,
    onClose,
    getTooltipMessage,
    disableCheckbox,
    onIncreaseProducts,
    onDecreaseProducts,
    onChangeQuantityProducts,
    onSelectProduct,
    onConfirm,
    onSubmit,
    onSearch,
    productFilterData,
    onChangeWarehouse,
    onChangeCategory,
    payload,
    openProductModal,
    onDeleteOrderItem,
    goBack,
    updateAddon,
    syncOrder,
  } = props;
  const { theme } = useTheme();
  const childCategory =
    productFilterData.categories
      .find((item) => item.item.id === payload.category_id)
      ?.item.sub_categories.map((item) => {
        return {
          label: item.name,
          value: item.id,
          item,
        };
      }) || [];
  return (
    <StyledOrderDetailSection>
      <form>
        <StyledRow gutter={[20, 16]}>
          <Col className="gutter-row name-type-col name-type-col-left" md={24} lg={24} xl={24}>
            <Collapse defaultActiveKey={['1', '2', '3', '4', '5', '6']} onChange={() => {}}>
              <Panel header="Thông tin đơn hàng" key="1">
                <OrderInfo
                  syncOrder={syncOrder}
                  getValues={getValues}
                  setValue={setValue}
                  register={register}
                  control={control}
                  updateAddon={updateAddon}
                />
              </Panel>
              <Panel header="Thông tin khách hàng" key="2">
                <CustomerInfo
                  getValues={getValues}
                  setValue={setValue}
                  register={register}
                  control={control}
                />
              </Panel>
              <Panel header="Thông tin người bán hàng" key="3">
                <SellerInfo
                  getValues={getValues}
                  setValue={setValue}
                  register={register}
                  control={control}
                />
              </Panel>
              <Panel header="Thông tin sản phẩm" key="5">
                <ProductItemList
                  onChangeQuantity={onChangeQuantity}
                  orderList={order.order_items}
                  onIncrease={onIncrease}
                  onDecrease={onDecrease}
                  onDeleteOrderItem={onDeleteOrderItem}
                />
                <div
                  onClick={() => {
                    openProductModal();
                  }}
                  className="repeater-add"
                >
                  <div className="text-block">
                    <PlusIcon size={24} color={MAIN_THEME_DATA.mainColor} />
                    <span>Thêm sản phẩm</span>
                  </div>
                </div>
              </Panel>
              <Panel header="Đánh giá từ khách hàng" key="6">
                {!order.reviews.length ? (
                  <span>Chưa có đánh giá</span>
                ) : (
                  <>
                    {order.reviews.map((item) => {
                      return (
                        <div className="review-item">
                          <div className="customer-block">
                            <SharedImage containerClassName="img" src={IMAGES.noImage} />
                            <div className="info-block">
                              <span className="full-name">{item.user.full_name}</span>
                              <span>{item.user.username}</span>
                              <Rate disabled value={5} />
                              <span>{item.content}</span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </Panel>
            </Collapse>
          </Col>
        </StyledRow>
        <div className="bottom-content">
          <div className="form__actions">
            <SharedButton
              text="Huỷ"
              className="cancel__btn"
              backgroundColor="transparent"
              textColor={theme?.colors?.button?.text || themes.theme.light.colors.button.text}
              borderColor={theme?.colors?.button?.border || themes.theme.light.colors.button.border}
              btnStyle="basic"
              onClick={goBack}
            />
            <SharedButton
              onClick={onSubmit}
              text={'Lưu'}
              className="create-rule__button"
              backgroundColor={MAIN_THEME_DATA.mainColor}
              btnStyle="basic"
            />
          </div>
        </div>
      </form>
      {opened && (
        <AppModal open={opened}>
          <form className="modal">
            <h2>Cập nhật sản phẩm</h2>
            <div className="filter-block">
              <ShareInput
                containerClassName="search__box"
                placeholder="Tìm theo tên"
                className="search__input"
                type="text"
                onChange={(e: any) => {
                  onSearch(e.target.value);
                }}
                prevIcon={<SearchIcon />}
              />
              <ShareSelectInput
                onChange={(value) => {
                  onChangeWarehouse(value);
                }}
                containerClassName="filter-wrapper"
                placeholder="Chọn kho"
                data={productFilterData.warehouses}
              />
              <ShareSelectInput
                onChange={onChangeCategory}
                containerClassName="filter-wrapper"
                placeholder="Chọn danh mục cha"
                data={productFilterData.categories}
              />
              <ShareSelectInput
                onChange={onChangeCategory}
                containerClassName="filter-wrapper"
                placeholder="Chọn danh mục con"
                data={[
                  {
                    label: 'Tất cả',
                    value: 'null',
                    item: DEFAULT_CATEGORY_FILTER_ITEM,
                  },
                  ...childCategory,
                ]}
              />
            </div>
            <div className="product-block">
              <div id="scrollableDiv" className="list">
                <InfiniteScroll
                  dataLength={productData.products.length}
                  next={loadMoreData}
                  hasMore={productData.products.length < productData.amount}
                  loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                  endMessage={
                    <Divider plain>Đã tải {productData.products.length} sản phẩm</Divider>
                  }
                  scrollableTarget="scrollableDiv"
                >
                  <List
                    dataSource={productData.products}
                    renderItem={(item) => (
                      <List.Item key={item.id}>
                        <div className="item-list">
                          <div className="left-block">
                            <SharedImage containerClassName="img-ctn" src={item.images?.[0]?.url} />
                            <div className="item-info">
                              <span className="product-name">{item.name}</span>
                              <div className="classification-wrapper">
                                {item.classifications?.length > 0 && (
                                  <span>{item.classifications?.[0]?.attribute_name + ':'}</span>
                                )}
                                <div className="classification-block">
                                  {item.classifications.map((val) => {
                                    return (
                                      <StyledClassification
                                        key={val.id}
                                        onClick={() => {
                                          onSelectClassification(item, val);
                                        }}
                                        isActive={val.id === item.selectedClassification?.id}
                                      >
                                        <span>{val.value}</span>
                                      </StyledClassification>
                                    );
                                  })}
                                </div>
                              </div>
                              {item.selectedClassification?.id && (
                                <div className="classification-wrapper m-top-1">
                                  {item.selectedClassification?.sub_classifications.length > 0 && (
                                    <span>
                                      {item.selectedClassification?.sub_classifications?.[0]
                                        ?.attribute_name + ':'}
                                    </span>
                                  )}
                                  <div className="classification-block">
                                    {item.selectedClassification?.sub_classifications.map((val) => {
                                      return (
                                        <StyledClassification
                                          key={val.id}
                                          onClick={() => {
                                            onSelectSubClassification(item, val);
                                          }}
                                          isActive={val.id === item.selectedSubClassification?.id}
                                        >
                                          <span>{val.value}</span>
                                        </StyledClassification>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                              <span className="warehouse-text">Kho: {item.warehouse.name}</span>
                              <span className="warehouse-text">
                                Hàng trong kho: {item.quantity}
                              </span>
                              <QuantityControl
                                disabled={item.quantity === 0}
                                size="small"
                                quantity={item?.amount || 0}
                                onIncrease={() => {
                                  onIncreaseProducts(item);
                                }}
                                onDecrease={() => {
                                  onDecreaseProducts(item);
                                }}
                                onChangeQuantity={(value) => {
                                  onChangeQuantityProducts(item, value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="right-block">
                            <span className="price">{toVND(item?.selling_price || 0)}</span>
                            <CustomTooltip
                              isButtonDisabled={disableCheckbox(item)}
                              message={getTooltipMessage(item)}
                            >
                              <SharedCheckbox
                                checked={item.isSelected}
                                onChange={() => {
                                  onSelectProduct(item);
                                }}
                                disabled={disableCheckbox(item)}
                                text=""
                              />
                            </CustomTooltip>
                          </div>
                        </div>
                      </List.Item>
                    )}
                  />
                </InfiniteScroll>
              </div>
            </div>
            <IConCancel onClick={onClose} className="cancel" />
            <div className="button-wrapper">
              <SharedButton
                onClick={onConfirm}
                className="btn-save"
                textColor="white"
                backgroundColor={MAIN_THEME_DATA.mainColor}
                text={'Cập nhật'}
              />
            </div>
          </form>
        </AppModal>
      )}
    </StyledOrderDetailSection>
  );
};
const StyledOrderDetailSection = styled.div`
  .warehouse-text {
    margin-top: 1rem;
  }
  width: 100%;
  .modal {
    width: 100rem;
    position: relative;
    .search__box {
      min-width: 20rem;
      width: fit-content;
      .search__input {
        height: 4.5rem;
      }
    }
    .filter-block {
      display: flex;
      .filter-wrapper {
        width: 20rem;
        margin-left: 1rem;
        .ant-select-selector {
          height: 4.5rem !important;
        }
      }
    }
    .cancel {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
    .product-block {
      .list {
        height: 50rem;
        margin-top: 2rem;
        overflow: auto;
        padding: 0 1.6rem;
        width: 100%;
        border: 1px solid rgba(140, 140, 140, 0.35);
        .item-list {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .right-block {
            display: flex;
          }
          .left-block {
            display: flex;
          }
          .price {
            display: inline-block;
            margin-right: 1rem;
            font-size: 1.7rem;
          }
          .item-info {
            margin-left: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .product-name {
              font-size: 1.7rem;
              margin-bottom: 1rem;
            }
            .classification-wrapper {
              display: flex;
              align-items: center;
              .classification-block {
                display: flex;
                margin-left: 1rem;
              }
            }
          }
          .img-ctn {
            width: 9.6rem;
            aspect-ratio: 1;
            border-radius: 8px;
            img {
              border-radius: 8px;
            }
          }
        }
      }
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
      .submit-btn {
        padding: 0.8rem 1.2rem;
        height: fit-content;
        align-self: center;
      }
      .inp-wrapper {
        width: 40%;
      }
    }
    @media (max-width: 1280px) {
      width: 70rem;
    }
    @media (max-width: 820px) {
      width: 50rem;
    }
    h2 {
      text-align: center;
      margin-bottom: 1rem;
    }
    .button-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
      .btn-save {
        align-self: center;
        width: fit-content;
        padding: 1rem 2rem;
      }
      .btn-cancel {
        margin-right: 1rem;
      }
    }
  }
  .repeater-add {
    cursor: pointer;
    height: 4rem;
    border: 1px dashed ${MAIN_THEME_DATA.mainColor};
    width: 100%;
    border-radius: 0.6rem;
    margin-top: 1.5rem;
    .text-block {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      span {
        font-weight: bold;
        margin-left: 1rem;
        font-size: larger;
        color: ${MAIN_THEME_DATA.mainColor};
      }
    }
  }
  .form-section {
    position: relative;
    padding: 3.2rem 0 0;
    border-radius: 0.6rem;
    background: ${(p) => p.theme.colors.bgSection};
  }

  .section-box {
    background: ${(p) => p.theme.colors.bgSection};
    border-radius: 0.6rem;
    padding: 1.6rem 2.2rem;
    box-shadow: 0px 3px 20px #0000000b;
    .box-content {
      padding: 2.6rem;
      .head {
        padding-bottom: 2.3rem;
        .box-title {
          font-size: 1.6rem;
        }
      }
      .main_form {
        display: flex;
        flex-direction: column;
        width: 100%;
        .form-field {
          display: flex;
          flex-direction: column;
          margin-bottom: 2.6rem;
          &:last-child {
            margin-bottom: 0;
          }
          .right-field {
            width: 100%;
          }
          .label {
            margin-right: 2.6rem;
            .label-text {
              font-size: 1.4rem;
              font-weight: 500;
              margin-bottom: 0.6rem;
              color: ${({ theme }: any) => theme?.colors?.text};
            }
            .label-desc {
              font-size: 1.3rem;
              font-weight: 400;
              color: ${({ theme }: any) => theme?.colors?.secondary};
            }
          }
          .value-text {
            font-size: 1.4rem;
            font-weight: 500;
            color: ${({ theme }: any) => theme?.colors?.secondary};
          }
          @media (min-width: 1280px) {
            flex-direction: row;
            align-items: flex-start;
            .label {
              width: 43%;
            }
          }
        }

        .cannot-changed-field {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
        .shared-input {
          margin-bottom: 0.8rem;
        }
        .inner-input,
        .ant-picker,
        input,
        .ant-select-selector {
          @media (min-width: 768px) {
            height: 4rem;
          }
        }
      }
    }
  }
  .edit_form {
    padding: 0 0 1.2rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    .shared-input,
    .ant-select {
      margin-bottom: 2rem;
    }

    @media (min-width: 768px) {
      .name-type-col-left {
        padding-right: 5rem;
      }
      .mob-row {
        margin-bottom: 3.2rem;
      }
    }

    @media (min-width: 1200px) {
      .name-type-col {
        max-width: calc((100% * 2 / 3 - 11.2rem) / 2);
      }
      .name-type-col-left {
        padding-right: 0;
        margin-right: 4.2rem;
      }
      .spent-get-col {
        padding-right: 1.5rem;
      }
      .duration-col {
        padding-left: 1.5rem;
      }
    }
  }

  .right-box {
    background: ${(p) => p.theme.colors.bgSection};
    border-radius: 0.6rem;
    padding: 2rem 2.2rem;
    box-shadow: 0px 3px 20px #0000000b;

    .label {
      display: flex;
      align-items: center;
      margin-bottom: 0.8rem;
      font-size: 1.6rem;
      font-weight: 500;
    }

    .upload__image {
      width: 100%;
      height: auto;
    }
  }
  .bottom-content {
    width: 100%;
    display: flex;
    margin-top: 3rem;
    align-items: center;
    justify-content: center;
    .form__actions {
      display: flex;
      width: 100%;
      .btn {
        width: calc((100% - 2rem) / 2);
        &:first-child {
          margin-right: 2rem;
        }
      }
      @media (min-width: 1280px) {
        display: flex;
        width: 100%;
        align-items: center;
        max-width: 23rem;
      }

      @media (max-width: 820px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 0;
        .btn {
          width: 100%;
          &:first-child {
            margin-right: 0;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
  .m-top-1 {
    margin-top: 1rem;
  }
`;
const StyledRow = styled((props: any) => <Row {...props} />)`
  margin-bottom: 1.4rem;
  @media (min-width: ${MAT_SM_SCREEN_WIDTH_MIN}) {
    margin-bottom: 4.2rem;
    &:first-child {
      margin-bottom: 5.6rem;
    }
  }
  width: 100%;
  .review-item {
    .customer-block {
      display: flex;
      .img {
        width: 3rem;
        aspect-ratio: 1;
        img {
          border-radius: 100px;
        }
      }
      .info-block {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;
        .full-name {
          font-size: 1.7rem;
        }
      }
    }
  }
`;
const StyledClassification = styled.div<{ isActive: boolean }>`
  padding: 0.4rem 0.8rem;
  border-width: 1px;
  border-radius: 6px;
  margin-right: 1rem;
  border-color: ${(p) => `${p?.isActive ? MAIN_THEME_DATA.mainColor : '#D8D8D8'}`};
  cursor: pointer;
`;
