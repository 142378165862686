import { SellerFormModule } from '@modules';
import { useAdminAuthorization } from '@utils';
import styled from 'styled-components';

export const EditSellerFormPage = () => {
  const { authorized } = useAdminAuthorization();
  if (!authorized)
    return <h1>Bạn không có quyền sử dụng chức năng này. Vui lòng liên hệ quản trị viên</h1>;
  return (
    <StyledMemberProfilePage className="edit-members-profile-page">
      <div className="page__title">
        <h2 className="title">Sửa thông tin seller</h2>
      </div>
      <SellerFormModule isEdit />
    </StyledMemberProfilePage>
  );
};

const StyledMemberProfilePage = styled.div`
  .page__title {
    margin-bottom: 2rem;
    .title {
      font-size: 1.8rem;
      font-weight: 500;
    }
  }

  .page__section {
    position: relative;
    padding: 2rem 2.8rem;
    border-radius: 0.6rem;
    background: ${(p) => p.theme.colors.bgSection};
    box-shadow: 0px 3px 20px #0000000b;
  }
`;
