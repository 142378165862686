import { useMemo, useState } from 'react';

import { dashboardAPI, memberAPI } from '@api';
import { AppDashboard } from '@components';
import { MAX_ITEM_PER_PAGE } from '@configs';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CustomerPaymentColumn,
  CustomerPaymentData,
  CustomerPaymentsResponse,
  DebtStatisticColumn,
  DebtStatisticData,
  DebtStatisticResponse,
  PayDebtPayload,
  ProductItemColumn,
  ProductItemStatisticResponse,
  ProductStatisticColumn,
  ProductStatisticData,
  ProductStatisticResponse,
  RootResponse,
} from '@interfaces';
import { setLoading, setTableLoading, useAppDispatch } from '@redux';
import { LogApp, convertPriceToInt, generateRandomId, toVND, useFilters } from '@utils';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import useDeepCompareEffect from 'src/utils/hooks/useDeepCompareEffect';
import { useImmer } from 'use-immer';
import * as yup from 'yup';
const schema = yup.object().shape({
  money: yup.string().required('Vui lòng nhập số tiền thanh toánh'),
});
const TABS = {
  DEBT: '1',
  PRODUCT: '2',
};
export const DashboardModule = () => {
  const {
    register,
    handleSubmit,
    setError,
    reset,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useAppDispatch();
  const [debtStatistic, setDebtStatistic] = useImmer<DebtStatisticData>({
    limit: 0,
    count: 0,
    data: [],
  });
  const [productStatistic, setProductStatistic] = useImmer<ProductStatisticData>({
    limit: 0,
    count: 0,
    data: [],
  });
  const [customerPaymentData, setCustomerPaymentData] = useImmer<CustomerPaymentData>({
    total_money: '',
    total_money_paid: '',
    total_money_debt: '',
    data: [],
  });
  const [productItemData, setProductItemData] = useImmer<ProductItemColumn[]>([]);
  const [currentTab, setCurrentTab] = useState(TABS.DEBT);
  const [productModal, setProductModal] = useImmer({
    opened: false,
    productName: '',
  });
  const [opened, setOpened] = useState(false);
  const {
    filters,
    onSelectDebtWarehouse,
    payloads,
    onSelectDebtCustomer,
    onSelectDebtSeller,
    customerRef,
    sellerRef,
    onSelectDebtCustomerLevel,
    onSelectProductWarehouse,
    onProductPageChange,
    onDebtPageChange,
    onProductDateChange,
    onDebtDateChange,
    warehouseSelected,
    onProductSearch,
    onDebtSearch,
  } = useFilters();
  const debtColumns: ColumnsType<DebtStatisticColumn> = useMemo(() => {
    return [
      {
        title: 'STT',
        dataIndex: 'no',
        key: 'no',
        render: (text, record, index) => index + payloads.debt.page * Number(10) + 1,
      },
      { title: 'Tên khách hàng', dataIndex: 'customer_name', key: 'customer_name' },
      { title: 'Nhóm khách hàng', dataIndex: 'customer_level', key: 'customer_level' },
      { title: 'Tên seller', dataIndex: 'seller_name', key: 'seller_name' },
      { title: 'Doanh số', dataIndex: 'revenue', key: 'revenue' },
      { title: 'Công nợ', dataIndex: 'debt', key: 'debt' },
      { title: 'Số dư', dataIndex: 'total_money_remain', key: 'total_money_remain' },
      { title: 'Doanh số tính thưởng', dataIndex: 'total_money_reward', key: 'total_money_reward' },
      { title: 'Thời gian nợ', dataIndex: 'debt_time', key: 'debt_time' },
    ];
  }, [payloads.debt.page]);
  const productColumns: ColumnsType<ProductStatisticColumn> = useMemo(() => {
    return [
      {
        title: 'STT',
        dataIndex: 'no',
        key: 'no',
        render: (text, record, index) => index + payloads.product.page * Number(10) + 1,
      },
      { title: 'Tên sản phẩm', dataIndex: 'product_name', key: 'product_name' },
      { title: 'Mã sản phẩm', dataIndex: 'code', key: 'code' },
      { title: 'Kho', dataIndex: 'warehouse', key: 'warehouse' },
      // { title: 'Doanh số', dataIndex: 'turnover', key: 'turnover' },
      { title: 'Đã bán', dataIndex: 'sold_quantity', key: 'sold_quantity' },
      // { title: 'Hàng tồn', dataIndex: 'remain', key: 'remain' },
      { title: 'Lượt xem', dataIndex: 'view', key: 'view' },
    ];
  }, [payloads.product.page]);
  const debtOrderColumns: ColumnsType<CustomerPaymentColumn> = useMemo(() => {
    return [
      {
        title: 'STT',
        dataIndex: 'no',
        key: 'no',
        // render: (text, record, index) => index + 0 * Number(10) + 1,
      },
      { title: 'Mã đơn hàng', dataIndex: 'order_code', key: 'order_code' },
      { title: 'Tên khách hàng', dataIndex: 'customer_name', key: 'customer_name' },
      { title: 'Tổng tiền', dataIndex: 'total_money', key: 'total_money' },
      { title: 'Tổng tiền nợ', dataIndex: 'money_debt', key: 'money_debt' },
      { title: 'Phí vận chuyển', dataIndex: 'money_addon', key: 'money_addon' },
      { title: 'Đã thanh toán', dataIndex: 'money_paid', key: 'money_paid' },
      // { title: 'Tiền thừa', dataIndex: 'total_money_remain', key: 'total_money_remain' },
      { title: 'Thời gian', dataIndex: 'time', key: 'time' },
      { title: 'Hành động', dataIndex: 'action', key: 'action' },
      // {
      //   title: 'Thanh toán',
      //   dataIndex: 'x',
      //   key: 'actions',
      //   render: (_, record, index) => {
      //     return (
      //       <>
      //         <Controller
      //           control={control}
      //           name={`order_${index}`}
      //           render={({ field: { onChange, value } }) => {
      //             return (
      //               <SharedCheckbox
      //                 onChange={(e: any) => {
      //                   onChange(e);
      //                   if (e.target.checked) setValue(`order_${index}`, record.key);
      //                   else setValue(`order_${index}`, '');
      //                 }}
      //                 checked={value}
      //                 className="justify-center"
      //               />
      //             );
      //           }}
      //         />
      //       </>
      //     );
      //   },
      // },
    ];
  }, []);
  const productItemColumns: ColumnsType<ProductItemColumn> = useMemo(() => {
    return [
      {
        title: 'STT',
        dataIndex: 'no',
        key: 'no',
        render: (text, record, index) => {
          return index + 1;
        },
      },
      { title: 'Mã sản phẩm', dataIndex: 'code', key: 'code' },
      { title: 'Đã bán', dataIndex: 'sold_mount', key: 'sold_mount' },
      // { title: 'Doanh số', dataIndex: 'total_money_purchase', key: 'total_money_purchase' },
      // { title: 'Hàng tồn', dataIndex: 'remain_mount', key: 'remain_mount' },
    ];
  }, []);
  const onModalInteract = (value: boolean) => {
    setOpened(value);
    if (!value) {
      reset({
        money: '',
      });
    }
  };
  const onProductModalInteract = (value: boolean) => {
    setProductModal((d) => {
      d.opened = value;
    });
  };
  const getDebtStatistics = async () => {
    try {
      dispatch(setTableLoading(true));
      const res: RootResponse<DebtStatisticResponse> = await dashboardAPI.getDebtStatistics(
        payloads.debt,
      );
      const newResponse: DebtStatisticColumn[] = res.data.content.map((item, index) => {
        return {
          no: index + 1,
          key: generateRandomId(),
          customer_name: item.customer_item.name,
          seller_name: item.seller_item.name,
          customer_level: item.customer_type.name,
          debt_time: `${
            item.from_time ? moment(item.from_time).format('DD/MM/YYYY') : 'DD/MM/YYYY'
          } - ${item.to_time ? moment(item.to_time).format('DD/MM/YYYY') : 'DD/MM/YYYY'}`,
          revenue: toVND(item.total_revenue),
          debt: toVND(item.total_debt),
          customer_id: item.customer_item.id,
          total_money_remain: toVND(item.total_money_remain),
          total_money_reward: toVND(item.total_money_reward),
        };
      });
      setDebtStatistic({
        limit: MAX_ITEM_PER_PAGE,
        count: res.data.amount,
        data: newResponse,
      });
    } catch (error) {
      LogApp(error, 'errr');
    } finally {
      dispatch(setTableLoading(false));
    }
  };
  const getProductStatistics = async () => {
    try {
      dispatch(setTableLoading(true));
      const res: RootResponse<ProductStatisticResponse> = await dashboardAPI.getProductStatistics(
        payloads.product,
      );
      const newResponse: ProductStatisticColumn[] = res.data.content.map((item, index) => {
        return {
          no: index + 1,
          key: generateRandomId(),
          product_id: item.product_id,
          product_name: item.name,
          code: item.code,
          warehouse: item.warehouse.name,
          turnover: toVND(item.total_money_purchase),
          sold_quantity: item.total_sold_amount,
          // remain: item.total_remain_amount,
          view: item.total_view,
        };
      });
      setProductStatistic({
        limit: MAX_ITEM_PER_PAGE,
        count: res.data.amount,
        data: newResponse,
      });
    } catch (error) {
    } finally {
      dispatch(setTableLoading(false));
    }
  };
  const getDebtOrders = async (customerId: string) => {
    try {
      setValue('customer_id', customerId);
      dispatch(setLoading(true));
      const res: RootResponse<CustomerPaymentsResponse> =
        await memberAPI.getCustomerPaymentStatistic(customerId);
      const newResponse: CustomerPaymentColumn[] = res.data.payments.content.map((item, index) => {
        return {
          key: generateRandomId(),
          no: index + 1,
          order_code: item.code,
          customer_name: item.customer.name,
          money_debt: toVND(item.money_debt),
          money_paid: toVND(item.money_paid),
          money_addon: toVND(item.money_addon),
          total_money: toVND(item.total_money),
          time: moment(item.created_at).format('HH:mm:ss DD/MM/YYYY'),
          total_money_remain: toVND(item.total_money_remain),
          action: item.code ? 'Đặt hàng' : 'Thanh toán',
        };
      });
      const { total_money, total_money_debt, total_money_paid } = res.data;
      setCustomerPaymentData({
        total_money: toVND(total_money),
        total_money_paid: toVND(total_money_paid),
        total_money_debt: toVND(total_money_debt),
        data: newResponse,
      });
      onModalInteract(true);
    } catch (error) {
      LogApp(error, 'err');
    } finally {
      dispatch(setLoading(false));
    }
  };
  const getProductItemStatistic = async (productId: string, productName: string) => {
    try {
      dispatch(setLoading(true));
      const res: RootResponse<ProductItemStatisticResponse[]> =
        await dashboardAPI.getProductItemStatistics(
          productId,
          payloads.product.from_time,
          payloads.product.to_time,
        );
      const newResponse: ProductItemColumn[] = res.data.map((item, index) => {
        return {
          key: item.id,
          no: index + 1,
          code: item.code,
          sold_mount: item.sold_mount,
          remain_mount: item.remain_mount,
          total_money_purchase: toVND(item.total_money_purchase),
        };
      });
      setProductItemData(newResponse);
      setProductModal((d) => {
        (d.opened = true), (d.productName = productName);
      });
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
  const handlePayment = handleSubmit(async (value) => {
    try {
      const payload: PayDebtPayload = {
        customer_id: getValues('customer_id'),
        amount: convertPriceToInt(getValues('money')),
        order_ids: null,
      };
      dispatch(setLoading(true));
      const res = await dashboardAPI.payDebtOrders(payload);
      toast.success('Thành công', {
        position: 'top-right',
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: true,
      });
      onModalInteract(false);
      await getDebtStatistics();
    } catch (err: any) {
    } finally {
      dispatch(setLoading(false));
    }
  });
  const onTabChange = (key: string) => {
    setCurrentTab(key);
  };
  useDeepCompareEffect(() => {
    if (currentTab === TABS.DEBT) getDebtStatistics();
  }, [payloads.debt, currentTab]);
  useDeepCompareEffect(() => {
    if (currentTab === TABS.PRODUCT) getProductStatistics();
  }, [payloads.product, currentTab]);
  return (
    <AppDashboard
      debtStatistic={debtStatistic}
      productStatistic={productStatistic}
      columns={debtColumns}
      productColumns={productColumns}
      filters={filters}
      payloads={payloads}
      onSelectDebtWarehouse={onSelectDebtWarehouse}
      onSelectDebtCustomer={onSelectDebtCustomer}
      onSelectDebtSeller={onSelectDebtSeller}
      onSelectDebtCustomerLevel={onSelectDebtCustomerLevel}
      customerRef={customerRef}
      sellerRef={sellerRef}
      getDebtOrders={getDebtOrders}
      getProductItemStatistic={getProductItemStatistic}
      customerPaymentData={customerPaymentData}
      productItemData={productItemData}
      onModalInteract={onModalInteract}
      onProductModalInteract={onProductModalInteract}
      opened={opened}
      debtOrderColumns={debtOrderColumns}
      productItemColumns={productItemColumns}
      register={register}
      errors={errors}
      control={control}
      handlePayment={handlePayment}
      onSelectProductWarehouse={onSelectProductWarehouse}
      productModal={productModal}
      onProductPageChange={onProductPageChange}
      onDebtPageChange={onDebtPageChange}
      onProductDateChange={onProductDateChange}
      onDebtDateChange={onDebtDateChange}
      warehouseSelected={warehouseSelected}
      onTabChange={onTabChange}
      onProductSearch={onProductSearch}
      onDebtSearch={onDebtSearch}
    />
  );
};
