import {
  CreateSellerPayload,
  UpdateAccountStatusPayload,
  UpdateCustomerPayload,
} from '@interfaces';
import { ApiClient, DefaultApiParams } from './axiosClient';

export const memberAPI = {
  getSeller: (id: string) => {
    const url = `/sellers/${id}`;
    return ApiClient.get(url);
  },
  getSellers: (params: DefaultApiParams) => {
    const url = '/sellers';
    return ApiClient.get(url, { params });
  },
  getSellerItems: (warehouse_id: string) => {
    const url = '/sellers/items';
    return ApiClient.get(url, { params: { warehouse_id } });
  },
  getCustomerLevels: () => {
    const url = `admin/customer-levels`;
    return ApiClient.get(url);
  },
  createSeller: (payload: CreateSellerPayload) => {
    const url = '/sellers';
    return ApiClient.post(url, payload);
  },
  updateSeller: (id: string, payload: CreateSellerPayload) => {
    const url = `/sellers/${id}`;
    return ApiClient.put(url, payload);
  },
  //customer
  getCustomers: (params: DefaultApiParams) => {
    const url = '/customers';
    return ApiClient.get(url, { params });
  },
  getCustomerItems: (warehouse_id: string) => {
    const url = `/admin/customers/items`;
    return ApiClient.get(url, { params: { warehouse_id } });
  },
  changeAccountStatus: (id: string, payload: UpdateAccountStatusPayload) => {
    const url = `/admin/customers/${id}/active`;
    return ApiClient.put(url, payload);
  },
  updateCustomer: (id: string, payload: UpdateCustomerPayload) => {
    const url = `/admin/customers/${id}`;
    return ApiClient.put(url, payload);
  },
  changeSellerStatus: (id: string, status: number) => {
    const url = `/admin/accounts/${id}/active?status=${status}`;
    return ApiClient.put(url);
  },
  getCustomerPaymentStatistic: (id: string, params?: DefaultApiParams) => {
    const url = `/admin/customers/${id}/payments`;
    return ApiClient.get(url, { params });
  },
};
