import { IRoute } from '@interfaces';
import { AppLayout, LayoutError } from '@layouts';
import {
  BannersPage,
  CategoryListPage,
  CreateProductPage,
  CreatePromotionPage,
  CustomersPage,
  DashboardPage,
  EditProductPage,
  EditPromotionPage,
  EditSellerFormPage,
  ForgotPasswordPage,
  LoginPage,
  OrderDetailPage,
  OrderPage,
  PageError404,
  ProductPage,
  PromotionPage,
  ResetPasswordPage,
  SellFormPage,
  SellersPage,
  SignUpPage,
  VerifyEmailPage,
  WarehousePage,
} from '@pages';
import {
  PATH_404,
  PATH_CATEGORIES,
  PATH_CREATE_PRODUCT,
  PATH_CREATE_SELLER_PROFILE,
  PATH_CUSTOMERS,
  PATH_DASHBOARD,
  PATH_EDIT_PRODUCT,
  PATH_EDIT_SELLER_PROFILE,
  PATH_FORGOT_PASSWORD,
  PATH_HOME,
  PATH_LOGIN,
  PATH_ORDER,
  PATH_ORDER_DETAIL,
  PATH_PRODUCT,
  PATH_PROMOTION,
  PATH_RESET_PASSWORD,
  PATH_SELLER,
  PATH_SIGN_UP,
  PATH_VERIFY_EMAIL,
  PATH_WAREHOUSE,
  PATH_CREATE_PROMOTION,
  PATH_EDIT_PROMOTION,
  PATH_BANNERS,
} from './navigation';

export const routes: Array<IRoute> = [
  //dashboard
  { path: PATH_DASHBOARD, page: DashboardPage, layout: AppLayout },
  //categories
  { path: PATH_CATEGORIES, page: CategoryListPage, layout: AppLayout },
  { path: PATH_SELLER, page: SellersPage, layout: AppLayout },
  { path: PATH_CREATE_SELLER_PROFILE, page: SellFormPage, layout: AppLayout },
  { path: PATH_EDIT_SELLER_PROFILE, page: EditSellerFormPage, layout: AppLayout },
  { path: PATH_CUSTOMERS, page: CustomersPage, layout: AppLayout },
  { path: PATH_PROMOTION, page: PromotionPage, layout: AppLayout },
  { path: PATH_CREATE_PROMOTION, page: CreatePromotionPage, layout: AppLayout },
  { path: PATH_EDIT_PROMOTION, page: EditPromotionPage, layout: AppLayout },
  //Product
  { path: PATH_PRODUCT, page: ProductPage, layout: AppLayout },
  { path: PATH_CREATE_PRODUCT, page: CreateProductPage, layout: AppLayout },
  { path: PATH_EDIT_PRODUCT, page: EditProductPage, layout: AppLayout },
  //Warehouse
  { path: PATH_WAREHOUSE, page: WarehousePage, layout: AppLayout },
  //Order
  { path: PATH_ORDER, page: OrderPage, layout: AppLayout },
  { path: PATH_ORDER_DETAIL, page: OrderDetailPage, layout: AppLayout },
  //
  { path: PATH_BANNERS, page: BannersPage, layout: AppLayout },
  { path: PATH_HOME, page: DashboardPage, layout: AppLayout },
  { path: PATH_404, page: PageError404, layout: LayoutError },
  { page: PageError404, layout: LayoutError },
];

export const normalRoutes = [PATH_HOME];

export const authRoutes: Array<IRoute> = [
  //auth
  { path: PATH_LOGIN, page: LoginPage, auth: true },
  { path: PATH_SIGN_UP, page: SignUpPage, auth: true },
  { path: PATH_FORGOT_PASSWORD, page: ForgotPasswordPage, auth: true },
  { path: PATH_VERIFY_EMAIL, page: VerifyEmailPage, auth: true },
  { path: PATH_RESET_PASSWORD, page: ResetPasswordPage, auth: true },
  { page: PageError404, layout: LayoutError },
];
