import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { memberAPI, warehouseAPI } from '@api';
import { SellerForm } from '@components';
import { DEFAULT_SELLER_DETAIL, ROLES } from '@configs';
import {
  AddressItem,
  AutocompleteItem,
  CreateSellerPayload,
  CreateSellerRoot,
  DistrictRoot,
  ProvinceRoot,
  Seller,
  SellerAutocompleteData,
  SellerDetailRoot,
  WardRoot,
  WarehouseItem,
  WarehouseRoot,
} from '@interfaces';
import { selectApp, setLoading, useAppDispatch, useAppSelector } from '@redux';
import { LogApp } from '@utils';
import { DefaultSellerFormValidator, SellerFormValidator } from '@validations';
import { useImmer } from 'use-immer';

export const SellerFormModule = ({ isEdit }: { isEdit: boolean }) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { id } = useParams();

  const { themeMode } = useAppSelector(selectApp);
  const [seller, setSeller] = useState<Seller>(DEFAULT_SELLER_DETAIL);
  const [role, setRole] = useState(ROLES.SELLER);
  const [autocompleteData, setAutoCompleteData] = useImmer<SellerAutocompleteData>({
    provinces: [],
    districts: [],
    wards: [],
    warehouses: [],
  });
  const getValidator = () => {
    if (isEdit || role === ROLES.ACCOUNTANT) return DefaultSellerFormValidator;
    return SellerFormValidator;
  };
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setError,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getValidator()),
    defaultValues: useMemo(() => {
      return {
        brand_name: '',
        warehouse_id: '',
        username: '',
        password: '',
        confirm_password: '',
        full_name: '',
        phone_number: '',
        email: '',
        dob: '',
        province_code: '',
        district_code: '',
        ward_code: '',
        address_detail: '',
        code: '',
        role: ROLES.SELLER,
      };
    }, []),
  });
  const handleEdit = handleSubmit(async (value) => {
    try {
      dispatch(setLoading(true));
      const {
        brand_name,
        warehouse_id,
        username,
        password,
        confirm_password,
        full_name,
        phone_number,
        email,
        dob,
        province_code,
        district_code,
        ward_code,
        address_detail,
        code,
        role,
      } = value;
      const payload: CreateSellerPayload = {
        brand_name,
        warehouse_id,
        username,
        password,
        confirm_password,
        full_name,
        phone_number,
        email,
        dob,
        address: {
          province_code,
          district_code,
          ward_code,
          detail: address_detail,
        },
        accountant: role === ROLES.ACCOUNTANT,
        code,
      };
      const res: CreateSellerRoot = isEdit
        ? await memberAPI.updateSeller(id || '', payload)
        : await memberAPI.createSeller(payload);
      navigate(-1);
    } catch (err: any) {
    } finally {
      dispatch(setLoading(false));
    }
  });
  const handleCancel = () => {
    navigate(-1);
  };
  const onSelectAddress = (value: string, key: 'province_code' | 'district_code' | 'ward_code') => {
    setValue(key, value);
  };
  const onSelectProvince = async (value: string) => {
    try {
      onSelectAddress(value, 'province_code');
      const res: DistrictRoot = await warehouseAPI.getDistricts(value);
      const optionValues: AutocompleteItem<AddressItem>[] = res.data.districts.map((item) => {
        return {
          label: item.name,
          value: item.name,
          item,
        };
      });
      setAutoCompleteData((draft) => {
        draft.districts = optionValues;
      });
    } catch (error) {}
  };
  const onSelectDistrict = async (value: string) => {
    try {
      onSelectAddress(value, 'district_code');
      const res: WardRoot = await warehouseAPI.getWards(value);
      const optionValues: AutocompleteItem<AddressItem>[] = res.data.wards.map((item) => {
        return {
          label: item.name,
          value: item.name,
          item,
        };
      });
      setAutoCompleteData((draft) => {
        draft.wards = optionValues;
      });
    } catch (error) {}
  };
  const onSelectWard = (value: string) => {
    onSelectAddress(value, 'ward_code');
  };
  const getAutocompleteData = async () => {
    try {
      dispatch(setLoading(true));
      const warehouses: WarehouseRoot = await warehouseAPI.getWarehouses();
      const res: ProvinceRoot = await warehouseAPI.getProvinces();
      const optionValues: AutocompleteItem<AddressItem>[] = res.data.provinces.map((item) => {
        return {
          label: item.name,
          value: item.name,
          item,
        };
      });

      const warehouseOption: AutocompleteItem<WarehouseItem>[] =
        warehouses.data.warehouse_response.map((item) => {
          return {
            label: item.name,
            value: item.name,
            item,
          };
        });
      setAutoCompleteData((draft) => {
        draft.provinces = optionValues;
        draft.warehouses = warehouseOption;
      });
    } catch (error) {
      LogApp(error, 'err');
    } finally {
      dispatch(setLoading(false));
    }
  };
  const getSellerDetail = async () => {
    if (!id) return;
    try {
      const res: SellerDetailRoot = await memberAPI.getSeller(id);
      const { full_name, phone_number, email, dob, address, warehouse } = res.data;
      setSeller(res.data);
      reset({
        full_name,
        phone_number,
        email,
        dob,
        address_detail: address?.detail,
        warehouse_id: warehouse.id,
      });
      await onSelectProvince(address.provinces.code);
      await onSelectDistrict(address.districts.code);
      onSelectWard(address.wards.code);
    } catch (error) {
      LogApp(error, 'nhd');
    }
  };
  useEffect(() => {
    getAutocompleteData();
  }, []);
  useEffect(() => {
    if (isEdit) getSellerDetail();
  }, []);

  return (
    <SellerForm
      seller={seller}
      errors={errors}
      register={register}
      onSubmit={handleEdit}
      setValue={setValue}
      isEdit={isEdit}
      getValues={getValues}
      onCancel={handleCancel}
      watch={watch}
      onSelectProvince={onSelectProvince}
      onSelectDistrict={onSelectDistrict}
      onSelectWard={onSelectWard}
      autocompleteData={autocompleteData}
      setRole={setRole}
    />
  );
};
