import { DefaultApiParams } from '@api';
import { MAIN_THEME_DATA, ORDER_STATUSES } from '@configs';
import {
  AutocompleteItem,
  OrderAutocompleteData,
  OrderColumn,
  OrderData,
  Seller,
} from '@interfaces';
import { ColumnsType } from 'antd/lib/table';
import { BaseSyntheticEvent } from 'react';
import { Control, FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import { styled } from 'styled-components';
import { AppModal } from '../AppModal';
import { IConCancel, SearchIcon } from '../Icon';
import {
  Pagination,
  ShareInput,
  ShareRangeDateSelect,
  ShareSelectInput,
  SharedAutocompleteV2,
  SharedButton,
  SharedTable,
} from '../shared';
interface OrderSectionProps {
  columns: ColumnsType<OrderColumn>;
  orders: OrderData;
  control: Control<any, any>;
  onClose: () => void;
  onPageChange: (page: number) => void;
  modalData: { opened: boolean; id: string };
  errors: Partial<FieldErrorsImpl<any>>;
  handleSubmit: (e?: BaseSyntheticEvent<any, any, any> | undefined) => Promise<void>;
  onDeny: (e?: BaseSyntheticEvent<any, any, any> | undefined) => Promise<void>;
  register: UseFormRegister<any>;
  isAccountant: boolean;
  currentPage: number;
  autocompleteData: OrderAutocompleteData;
  onSellerChange: (id: string) => void;
  onCustomerChange: (id: string) => void;
  onKeyWordChange: (keyword: string) => void;
  onStatusChange: (value: string) => void;
  onDateChange: (value: string[]) => void;
  orderPayload: DefaultApiParams;
}
export const OrderSection = (props: OrderSectionProps) => {
  const {
    columns,
    orders,
    control,
    modalData,
    onClose,
    errors,
    handleSubmit,
    onDeny,
    register,
    isAccountant,
    currentPage,
    onPageChange,
    autocompleteData,
    onSellerChange,
    onCustomerChange,
    orderPayload,
    onKeyWordChange,
    onDateChange,
    onStatusChange,
  } = props;
  return (
    <StyledOrderSection>
      <StyledSearchWrapper>
        <div className="input-wrapper">
          <ShareInput
            containerClassName="search__box"
            placeholder="Tìm theo tên"
            className="search__input"
            type="text"
            onChange={(e: any) => {
              onKeyWordChange(e.target.value);
            }}
            prevIcon={<SearchIcon />}
          />
        </div>
        <SharedAutocompleteV2
          name="customer"
          isClearable
          containerClassName="filter-wrapper"
          data={autocompleteData.customers}
          placeholder="Chọn khách hàng"
          onSelect={(v) => {
            onCustomerChange(v.value);
          }}
        />
        <SharedAutocompleteV2
          name="seller"
          isClearable
          containerClassName="filter-wrapper"
          onSelect={(v: AutocompleteItem<Seller>) => {
            onSellerChange(v.value);
          }}
          data={autocompleteData.sellers}
          placeholder="Chọn seller"
        />
        <ShareSelectInput
          containerClassName="filter-wrapper"
          placeholder="Trạng thái đơn hàng"
          data={ORDER_STATUSES}
          onChange={(v) => {
            onStatusChange(v);
          }}
        />
        <ShareRangeDateSelect
          // defaultValue={[DEFAULT_FROM_DATE, DEFAULT_TO_DATE]}
          containerClassName="filter-wrapper"
          placeholder={['Từ ngày', 'Đến ngày']}
          format={'yyyy-MM-DD'}
          onChange={(date, dateString) => {
            onDateChange(dateString);
          }}
        />
      </StyledSearchWrapper>
      <SharedTable columns={columns} dataSource={orders.data} />
      <div className="bottom-pagination">
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={orders.count}
          pageSize={orders.limit}
          onPageChange={(page: number) => {
            onPageChange(page);
          }}
        />
      </div>
      {modalData.opened && (
        <>
          {!isAccountant ? (
            <AppModal open={modalData.opened}>
              {/*@ts-ignore*/}
              <form onSubmit={handleSubmit} className="modal">
                <h2>Thanh toán</h2>
                <div className="money-block">
                  <ShareInput
                    prefix="đ"
                    inputDefaultStyle="preTab"
                    control={control}
                    isNumericFormat
                    containerClassName="inp-wrapper"
                    name="total_money"
                    label="Tổng tiền"
                    disabled
                  />
                  <ShareInput
                    prefix="đ"
                    inputDefaultStyle="preTab"
                    control={control}
                    isNumericFormat
                    containerClassName="inp-wrapper"
                    name="money_paid"
                    label="Khách thanh toán"
                    errors={errors['money_paid']?.message}
                  />
                  <SharedButton
                    typeHtml="submit"
                    className="submit-btn"
                    backgroundColor={MAIN_THEME_DATA.mainColor}
                    textColor="white"
                    text="Xác nhận thanh toán"
                  />
                </div>
                <IConCancel onClick={onClose} className="cancel" />
              </form>
            </AppModal>
          ) : (
            <AppModal open={modalData.opened}>
              {/*@ts-ignore*/}
              <form onSubmit={onDeny} className="deny-modal">
                <h2>Từ chối đơn hàng</h2>
                <div className="reason-block">
                  <ShareInput
                    register={register}
                    containerClassName="inp-wrapper"
                    name="reason"
                    label="Lí do từ chối"
                    placeholder="Nhập lí do từ chối"
                    required
                    errors={errors['reason']?.message}
                  />
                  <SharedButton
                    typeHtml="submit"
                    className="submit-btn"
                    backgroundColor={MAIN_THEME_DATA.mainColor}
                    textColor="white"
                    text="Xác nhận"
                  />
                </div>
                <IConCancel onClick={onClose} className="cancel" />
              </form>
            </AppModal>
          )}
        </>
      )}
    </StyledOrderSection>
  );
};
const StyledOrderSection = styled.div`
  .ant-table-row {
    cursor: pointer;
  }
  .input-wrapper {
    margin-top: 1rem;
  }

  .bottom-pagination {
    margin-top: 3.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .pagination-bar {
      margin-top: 1rem;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .page-size {
      display: flex;
      align-items: center;
      .label {
        display: inline-block;
        white-space: nowrap;
        padding-right: 0.8rem;
      }
    }
  }
  .deny-modal {
    width: 50rem;
    position: relative;
    h2 {
      text-align: center;
      margin-bottom: 1rem;
    }
    .cancel {
      position: absolute;
      top: 0;
      right: 0;
    }
    .reason-block {
      display: flex;
      height: 10rem;
      .inp-wrapper {
        width: 70%;
      }
      .submit-btn {
        padding: 0.8rem 1.2rem;
        height: fit-content;
        align-self: center;
        margin-left: 2rem;
      }
    }
  }
  .modal {
    width: 100rem;
    position: relative;
    .cancel {
      position: absolute;
      top: 0;
      right: 0;
    }

    .money-block {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
      height: 10rem;
      .submit-btn {
        padding: 0.8rem 1.2rem;
        height: fit-content;
        align-self: center;
      }
      .inp-wrapper {
        width: 40%;
      }
    }
    .time-picker {
      padding: 0.6rem 1.2rem;
      /* height: 100%;
    width: 100%; */
      box-shadow: none !important;
      outline: none !important;
    }
    @media (max-width: 1280px) {
      width: 70rem;
    }
    @media (max-width: 820px) {
      width: 50rem;
    }
    .dropdown {
      margin-top: 1rem;
      .ant-select-selector {
        padding: 0.4rem !important;
      }
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      width: 100%;
      height: 38px;
      padding: 0 11px;
    }
    h2 {
      text-align: center;
      margin-bottom: 1rem;
    }
    .button-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 5rem;
      .btn-save {
        align-self: center;
        width: fit-content;
        padding: 1rem 2rem;
      }
      .btn-cancel {
        margin-right: 1rem;
      }
    }
    .branch-list-block {
      display: flex;
      justify-content: center;
      .branch-list-wrapper {
        h4 {
          margin-bottom: 1rem;
        }
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        background-color: #f1f5f9;
        padding: 1rem 0rem;
        .selected-list {
          width: 40%;
        }
        .available-list {
          width: 40%;
        }
        .move-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .move-btn {
            padding: 0.8rem 1rem;
            width: fit-content;
          }
        }
      }
    }
  }
`;
const StyledSearchWrapper = styled.div`
  .ant-picker {
    height: 4rem;
    margin-left: 1rem;
  }
  .filter-wrapper {
    width: 30rem;
    margin-left: 1rem;
  }
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 2rem;
  .search-order__box {
    min-width: 20rem;
    width: fit-content;
    .search-order__input {
      height: 4.5rem;
    }
  }
  .create-order__button {
    height: 4.5rem;
    width: fit-content;
  }
`;
