import { DefaultApiParams, bannerAPI } from '@api';
import {
  BannerList,
  HideIcon,
  PopoverPopup,
  SharedButton,
  SharedImage,
  ViewIcon,
} from '@components';
import { MAIN_THEME_DATA, MAX_ITEM_PER_PAGE } from '@configs';
import {
  BannerColumn,
  BannerData,
  BannerResponse,
  ChangeBannerStatusPayload,
  LocalBanner,
  RootResponse,
} from '@interfaces';
import { selectApp, setCurrentPage, setLoading, useAppDispatch, useAppSelector } from '@redux';
import { LogApp, generateRandomId } from '@utils';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { styled } from 'styled-components';
import { useImmer } from 'use-immer';
import * as yup from 'yup';

const schema = yup.object().shape({
  name: yup.string().required('Vui lòng nhập tên danh mục'),
});
const IMAGE_UPLOAD_LIMIT = 5;
const DEFAULT_BANNERS = Array.from({ length: IMAGE_UPLOAD_LIMIT }).map((_, index) => ({
  index: index + 1,
  id: generateRandomId(),
  key: generateRandomId(),
  file: null,
  url: '',
  name: '',
}));
export const BannersModule = () => {
  const { themeMode, currentPage } = useAppSelector(selectApp);
  const columns: ColumnsType<BannerColumn> = useMemo(() => {
    return [
      {
        title: 'STT',
        key: 'no',
        width: '5%',
        render: (text, record, index) => index + (currentPage || 0) * 10 + 1,
      },
      {
        title: 'Ảnh',
        dataIndex: 'image',
        key: 'image',
        width: '15%',
        render: (value: any) => {
          return (
            <StyledImageWrapper>
              <SharedImage containerClassName="img-ctn" src={value} />
            </StyledImageWrapper>
          );
        },
      },
      {
        title: 'Hành động',
        dataIndex: '',
        key: 'x',
        width: '10%',
        render: (value) => {
          const isActive = value.status;
          return (
            <div className="flex items-center justify-center">
              <PopoverPopup
                content={
                  <StyledConfirmPopup>
                    <h5 className="text-center items-center mb-2 text-current text-base">
                      Thông báo
                    </h5>
                    <p className="text-sm">
                      {isActive
                        ? `Bạn có muốn ẩn banner này không? (Sản phẩm này sẽ không xuất hiện trên trang bán hàng)`
                        : 'Bạn có muốn hiển thị banner này không?'}
                    </p>
                  </StyledConfirmPopup>
                }
                isHaveConfirmButton
                onConfirm={() => {
                  value.onChangeStatus();
                }}
              >
                <SharedButton
                  className={`btn-action ${isActive ? `btn-delete` : `btn-edit`}`}
                  backgroundColor={MAIN_THEME_DATA.mainColor}
                  prevIcon={isActive ? <HideIcon color={'white'} /> : <ViewIcon color={'white'} />}
                  textColor="white"
                  text={isActive ? 'Ẩn' : 'Hiển thị'}
                />
              </PopoverPopup>
            </div>
          );
        },
      },
    ];
  }, [currentPage]);
  const uploadImageRef = useRef<any>(null);
  const imageRef = useRef<LocalBanner[]>([]);
  const deleteImages = useRef<string[]>([]);
  const [bannerData, setBannerData] = useImmer<BannerData>({
    limit: 0,
    count: 0,
    data: [],
  });
  const [image, setImage] = useState(undefined);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [payload, setPayload] = useState<DefaultApiParams>({
    page: currentPage || 0,
    size: 10,
    keyword: '',
  });
  const onPageChange = (page: number) => {
    LogApp(page, 'page');
    dispatch(setCurrentPage(Number(page)));
    setPayload({ ...payload, page });
  };
  const [modalData, setModalData] = useImmer({
    visible: false,
    image: undefined,
  });
  const changeSelectedImage = (value: any) => {
    if (value) {
      setModalData((d) => {
        d.image = value;
      });
    }
  };
  const onModalInteract = ({ visible, image }: { visible: boolean; image: any }) => {
    setModalData((d) => {
      d.visible = visible;
      d.image = image;
    });
    if (visible) uploadImageRef.current?.removeImage();
  };
  const toastError = (msg: string) => {
    toast.error(`${msg}`, {
      position: 'top-right',
      autoClose: 2000,
      closeOnClick: true,
      pauseOnHover: true,
      theme: 'colored',
    });
  };
  const uploadBanners = async () => {
    if (!modalData.image) {
      toastError('Vui lòng chọn 1 ảnh');
      return;
    }
    try {
      dispatch(setLoading(true));
      const images = new FormData();
      images.append('files', modalData.image);
      await bannerAPI.uploadBanners(images);
      await getBanners();
      onModalInteract({ visible: false, image: undefined });
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getBanners = async () => {
    try {
      dispatch(setLoading(true));
      const res: RootResponse<BannerResponse> = await bannerAPI.getBanners({ page: payload.page });
      const newResponse: BannerColumn[] = res.data.content.map((item, index) => {
        return {
          key: item.id,
          no: index + 1,
          image: item.url,
          onChangeStatus: () => {
            changeBannerStatus(item.id, !item.status);
          },
          status: item.status,
        };
      });
      setBannerData({
        limit: MAX_ITEM_PER_PAGE,
        count: res.data.amount,
        data: newResponse,
      });
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
  const changeBannerStatus = async (id: string, status: boolean) => {
    try {
      dispatch(setLoading(true));
      const payload: ChangeBannerStatusPayload = {
        banner_ids: [id],
        status,
      };
      await bannerAPI.changeBannerStatus(payload);
      await getBanners();
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
  useEffect(() => {
    getBanners();
  }, [payload.page]);
  return (
    <BannerList
      changeSelectedImage={changeSelectedImage}
      uploadBanners={uploadBanners}
      uploadImageRef={uploadImageRef}
      bannerData={bannerData}
      columns={columns}
      currentPage={currentPage}
      onModalInteract={onModalInteract}
      modalData={modalData}
      onPageChange={onPageChange}
    />
  );
};
const StyledConfirmPopup = styled.div`
  .btn {
    padding: 0.4rem 0.3rem;
    /* max-width: 5rem; */
  }
`;
const StyledImageWrapper = styled.div`
  .img-ctn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    width: 50%;
    height: 50%;
  }
`;
