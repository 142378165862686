import {
  OrderFilterPayload,
  UpdateOrderStatusPayload,
  UpdateProductOrderPayload,
} from '@interfaces';
import { ApiClient, DefaultApiParams } from './axiosClient';

export const orderAPI = {
  getOrders: (params?: DefaultApiParams & OrderFilterPayload) => {
    const url = `/orders/filter/client?page=${params?.page}&size=10&all=false`;
    return ApiClient.post(url, { ...params, keyword: params?.keyword || null });
  },
  getOrder: (id: string) => {
    const url = `/orders/${id}`;
    return ApiClient.get(url);
  },
  payOrder: (id: string, status: number, payload?: UpdateOrderStatusPayload) => {
    const url = `/orders/${id}?status=${status}`;
    return ApiClient.post(url, payload);
  },
  updateOrder: (id: string, payload: UpdateProductOrderPayload) => {
    const url = `/orders/${id}`;
    return ApiClient.put(url, payload);
  },
  updateAddOn: (id: string, money: number) => {
    const url = `/orders/${id}/add-on?money-addon=${money}`;
    return ApiClient.post(url);
  },
  syncOrder: (id: string) => {
    const url = `/a3/sync-order?order-id=${id}`;
    return ApiClient.get(url);
  },
};
