import { memberAPI, warehouseAPI } from '@api';
import { DEFAULT_DASHBOARD_FILTER_ITEM } from '@configs';
import {
  AutocompleteItem,
  CustomerItem,
  CustomerLevel,
  DashboardFilter,
  FilterDropdowns,
  RootResponse,
  SellerItem,
  Warehouse,
  WarehouseRoot,
} from '@interfaces';
import { setTableLoading, useAppDispatch } from '@redux';
import { useEffect, useRef, useState } from 'react';
import { useImmer } from 'use-immer';
import { LogApp } from '../utilities';
import { debounce } from 'lodash';

export const useFilters = () => {
  const dispatch = useAppDispatch();
  const customerRef = useRef<any>(null);
  const sellerRef = useRef<any>(null);
  const [filters, setFilters] = useImmer<FilterDropdowns>({
    warehouses: [],
    customers: [],
    sellers: [],
    customerLevels: [],
  });
  const [payloads, setPayloads] = useImmer<DashboardFilter>({
    debt: DEFAULT_DASHBOARD_FILTER_ITEM,
    product: DEFAULT_DASHBOARD_FILTER_ITEM,
  });
  const [warehouseSelected, setWarehouseSelected] = useState<string | null>(null);
  const onSelectDebtWarehouse = (item: AutocompleteItem<Warehouse>) => {
    if (!item.value) setWarehouseSelected(null);
    else setWarehouseSelected(item.item.id);
    setPayloads((draft) => {
      draft.debt.customer_id = null;
      draft.debt.seller_id = null;
    });
    customerRef.current?.clearValue?.();
    sellerRef.current?.clearValue?.();
  };
  const onSelectProductWarehouse = (item: AutocompleteItem<Warehouse>) => {
    setPayloads((draft) => {
      if (!item.value) draft.product.warehouse_id = item.value;
      else draft.product.warehouse_id = item.item.id;
    });
  };
  const onSelectDebtCustomer = (item: AutocompleteItem<CustomerItem>) => {
    setPayloads((draft) => {
      if (!item.value) draft.debt.customer_id = null;
      else draft.debt.customer_id = item.item.id;
    });
  };
  const onSelectDebtCustomerLevel = (item: AutocompleteItem<CustomerLevel>) => {
    setPayloads((draft) => {
      if (!item.value) draft.debt.customer_type_id = null;
      else draft.debt.customer_type_id = item.item.id;
    });
  };
  const onSelectDebtSeller = (item: AutocompleteItem<SellerItem>) => {
    setPayloads((draft) => {
      if (!item.value) draft.debt.seller_id = null;
      else {
        draft.debt.seller_id = item.item.id;
        draft.debt.customer_id = null;
      }
      customerRef.current?.select?.clearValue?.();
    });
  };
  const getDropdownData = async () => {
    try {
      dispatch(setTableLoading(true));
      const warehouses: WarehouseRoot = await warehouseAPI.getWarehouses({ all: true });
      const customerLevels: RootResponse<CustomerLevel[]> = await memberAPI.getCustomerLevels();
      setFilters((draft) => {
        draft.warehouses = warehouses.data.warehouse_response.map((item) => {
          return {
            label: item.name,
            value: item.id,
            item,
          };
        });
        draft.customerLevels = customerLevels.data.map((item) => {
          return {
            label: item.name,
            value: item.id,
            item,
          };
        });
      });
    } catch (error) {
      LogApp(error, 'err');
    } finally {
      dispatch(setTableLoading(false));
    }
  };
  const getMembersDropdown = async () => {
    if (!warehouseSelected) return;
    try {
      const customers: RootResponse<CustomerItem[]> = await memberAPI.getCustomerItems(
        warehouseSelected,
      );
      const sellers: RootResponse<SellerItem[]> = await memberAPI.getSellerItems(warehouseSelected);
      setFilters((draft) => {
        draft.customers = customers.data.map((item) => {
          return {
            label: item.full_name,
            value: item.id,
            item,
          };
        });
        draft.sellers = sellers.data.map((item) => {
          return {
            label: item.name,
            value: item.id,
            item,
          };
        });
      });
    } catch (error) {}
  };
  const onProductPageChange = (page: number) => {
    setPayloads((d) => {
      d.product.page = page;
    });
  };
  const onDebtPageChange = (page: number) => {
    setPayloads((d) => {
      d.debt.page = page;
    });
  };
  const onProductDateChange = (date: string[]) => {
    setPayloads((d) => {
      d.product.from_time = date[0];
      d.product.to_time = date[1];
    });
  };
  const onDebtDateChange = (date: string[]) => {
    setPayloads((d) => {
      d.debt.from_time = date[0];
      d.debt.to_time = date[1];
    });
  };
  const onProductSearch = debounce((value: string) => {
    setPayloads((d) => {
      if (value === '') d.product.keyword = null;
      else d.product.keyword = value.trim();
    });
  }, 500);
  const onDebtSearch = debounce((value: string) => {
    setPayloads((d) => {
      if (value === '') d.debt.keyword = null;
      else d.debt.keyword = value.trim();
    });
  }, 500);
  useEffect(() => {
    getDropdownData();
  }, []);

  useEffect(() => {
    getMembersDropdown();
  }, [warehouseSelected]);
  return {
    filters,
    onSelectDebtWarehouse,
    payloads,
    onSelectDebtCustomer,
    onSelectDebtSeller,
    customerRef,
    sellerRef,
    onSelectDebtCustomerLevel,
    onSelectProductWarehouse,
    onProductPageChange,
    onDebtPageChange,
    onProductDateChange,
    onDebtDateChange,
    warehouseSelected,
    onProductSearch,
    onDebtSearch,
  };
};
