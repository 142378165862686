export const PATH_HOME = '/';
export const PATH_DASHBOARD = '/dashboard';
export const PATH_CATEGORIES = '/categories';
export const PATH_SYSTEM_SETTING = '/settings';
export const PATH_PRODUCT = '/product';
export const PATH_MEMBER = '/members';
export const PATH_SELLER = '/sellers';
export const PATH_CUSTOMERS = '/customers';
export const PATH_PROMOTION = '/promotion';
export const PATH_CREATE_PROMOTION = PATH_PROMOTION + '/create';
export const PATH_EDIT_PROMOTION = PATH_PROMOTION + '/edit/:id';
//auth
export const PATH_LOGIN = '/login';
export const PATH_SIGN_UP = '/register';
export const PATH_FORGOT_PASSWORD = '/forgot-password';
export const PATH_VERIFY_EMAIL = '/verify-email';
export const PATH_RESET_PASSWORD = '/reset-password';
//Loyalty setting
export const PATH_CREATE_CATEGORIES = PATH_CATEGORIES + '/create';
export const PATH_EDIT_CATEGORIES = PATH_CATEGORIES + '/edit/:id';
//System settings
export const PATH_GENERAL = '/general';
export const PATH_USER_PROFILE = '/profile';
export const PATH_POLICY = '/policy';
export const PATH_BRANCH_GROUP = '/branch-group';
//Marketing
export const PATH_EDIT_PRODUCT = PATH_PRODUCT + '/edit/:id';
export const PATH_CREATE_PRODUCT = PATH_PRODUCT + '/create';
//MEMBER
export const PATH_MEMBER_PROFILE = PATH_MEMBER + '/member-profile/:memberId';
export const PATH_CREATE_SELLER_PROFILE = PATH_SELLER + '/create';
export const PATH_EDIT_SELLER_PROFILE = PATH_SELLER + '/edit/:id';
//Stores
export const PATH_GROUP = PATH_BRANCH_GROUP + '/groups';
export const PATH_WAREHOUSE = '/warehouse';
export const PATH_EDIT_STORE = PATH_WAREHOUSE + '/edit/:id';
//simulator
export const PATH_SIMULATOR = '/simulator';
//error
export const PATH_404 = '/*';
//order
export const PATH_ORDER = '/order';
export const PATH_ORDER_DETAIL = PATH_ORDER + '/:id';

export const PATH_BANNERS = '/banners';
