import styled from 'styled-components';

import { MAIN_THEME_DATA } from '@configs';
import {
  AutocompleteItem,
  CustomerItem,
  CustomerLevel,
  CustomerPaymentColumn,
  CustomerPaymentData,
  DashboardFilter,
  DebtStatisticColumn,
  DebtStatisticData,
  FilterDropdowns,
  ProductItemColumn,
  ProductStatisticColumn,
  ProductStatisticData,
  SellerItem,
  Warehouse,
} from '@interfaces';
import { useTheme } from '@theme';
import { LogApp } from '@utils';
import { Tabs } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { BaseSyntheticEvent } from 'react';
import { Control, FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import { AppModal } from '../AppModal';
import { IConCancel, SearchIcon } from '../Icon';
import {
  Pagination,
  ShareInput,
  ShareRangeDateSelect,
  SharedAutocompleteV2,
  SharedButton,
  SharedTable,
} from '../shared';

interface IProps {
  payloads: DashboardFilter;
  filters: FilterDropdowns;
  debtStatistic: DebtStatisticData;
  productStatistic: ProductStatisticData;
  columns: ColumnsType<DebtStatisticColumn>;
  productColumns: ColumnsType<ProductStatisticColumn>;
  debtOrderColumns: ColumnsType<CustomerPaymentColumn>;
  productItemColumns: ColumnsType<ProductItemColumn>;
  onSelectDebtWarehouse: (value: AutocompleteItem<Warehouse>) => void;
  onSelectProductWarehouse: (value: AutocompleteItem<Warehouse>) => void;
  onSelectDebtCustomer: (value: AutocompleteItem<CustomerItem>) => void;
  onSelectDebtSeller: (value: AutocompleteItem<SellerItem>) => void;
  onSelectDebtCustomerLevel: (value: AutocompleteItem<CustomerLevel>) => void;
  getDebtOrders: (customerId: string) => void;
  getProductItemStatistic: (productId: string, productName: string) => void;
  sellerRef: any;
  customerRef: any;
  opened: boolean;
  customerPaymentData: CustomerPaymentData;
  productItemData: ProductItemColumn[];
  onModalInteract: (value: boolean) => void;
  onProductModalInteract: (value: boolean) => void;
  register: UseFormRegister<any>;
  errors: Partial<FieldErrorsImpl<any>>;
  control: Control<any, any>;
  handlePayment: (e?: BaseSyntheticEvent<any, any, any> | undefined) => Promise<void>;
  productModal: { opened: boolean; productName: string };
  onDebtPageChange: (page: number) => void;
  onProductPageChange: (page: number) => void;
  onProductDateChange: (date: string[]) => void;
  onTabChange: (tab: string) => void;
  onDebtDateChange: (date: string[]) => void;
  onProductSearch: (value: string) => void;
  onDebtSearch: (value: string) => void;
  warehouseSelected: string | null;
}

export const AppDashboard = (props: IProps) => {
  const {
    payloads,
    filters,
    onSelectDebtWarehouse,
    onSelectDebtCustomer,
    onSelectDebtSeller,
    debtStatistic,
    columns,
    sellerRef,
    customerRef,
    getDebtOrders,
    customerPaymentData,
    opened,
    onModalInteract,
    debtOrderColumns,
    register,
    errors,
    control,
    handlePayment,
    onSelectDebtCustomerLevel,
    productColumns,
    productStatistic,
    onSelectProductWarehouse,
    productItemData,
    productModal,
    productItemColumns,
    onProductModalInteract,
    getProductItemStatistic,
    onDebtPageChange,
    onProductPageChange,
    onProductDateChange,
    onDebtDateChange,
    warehouseSelected,
    onTabChange,
    onProductSearch,
    onDebtSearch,
  } = props;
  const TABS = [
    {
      label: `Thống kê công nợ`,
      key: '1',
      children: (
        <>
          <div className="head__actions">
            <div className="debt-filter">
              <SharedAutocompleteV2
                name="warehouse"
                isClearable
                containerClassName="filter-wrapper"
                data={filters.warehouses}
                placeholder="Chọn kho"
                onSelect={(v: AutocompleteItem<Warehouse>) => {
                  onSelectDebtWarehouse(v);
                }}
              />
              {warehouseSelected && (
                <>
                  <SharedAutocompleteV2
                    selectRef={sellerRef}
                    name="seller"
                    isClearable
                    containerClassName="filter-wrapper"
                    onSelect={(v) => {
                      onSelectDebtSeller(v);
                    }}
                    data={filters.sellers}
                    placeholder="Chọn seller"
                  />
                  <SharedAutocompleteV2
                    selectRef={customerRef}
                    name="customer"
                    isClearable
                    containerClassName="filter-wrapper"
                    data={filters.customers}
                    placeholder="Chọn khách hàng"
                    onSelect={(v) => {
                      onSelectDebtCustomer(v);
                    }}
                  />
                </>
              )}

              <SharedAutocompleteV2
                name="customer_level"
                isClearable
                containerClassName="filter-wrapper"
                data={filters.customerLevels}
                placeholder="Chọn nhóm khách hàng"
                onSelect={(v) => {
                  onSelectDebtCustomerLevel(v);
                }}
              />
              <ShareRangeDateSelect
                inputClassName="range-date"
                containerClassName="range-data-wrapper"
                placeholder={['Từ ngày', 'Đến ngày']}
                format={'yyyy-MM-DD'}
                onChange={(date, dateString) => {
                  onDebtDateChange(dateString);
                }}
                // defaultValue={[DEFAULT_FROM_DATE, DEFAULT_TO_DATE]}
              />
              <ShareInput
                containerClassName="filter-wrapper"
                placeholder="Tìm kiếm"
                className="search-rules__input"
                type="text"
                onChange={(e: any) => {
                  onDebtSearch(e.target.value);
                }}
                prevIcon={<SearchIcon />}
              />
            </div>
          </div>
          <SharedTable
            onRowClick={(item: DebtStatisticColumn) => {
              getDebtOrders(item.customer_id);
            }}
            columns={columns}
            dataSource={debtStatistic.data}
          />
          <div className="bottom-pagination">
            <Pagination
              className="pagination-bar"
              currentPage={payloads.debt.page}
              totalCount={debtStatistic.count}
              pageSize={debtStatistic.limit}
              onPageChange={(page: number) => {
                onDebtPageChange(page);
              }}
            />
          </div>
        </>
      ),
    },
    {
      label: 'Thống kê sản phẩm',
      key: '2',
      children: (
        <>
          <div className="head__actions">
            <div className="debt-filter">
              <SharedAutocompleteV2
                name="warehouse"
                isClearable
                containerClassName="filter-wrapper"
                data={filters.warehouses}
                placeholder="Chọn kho"
                onSelect={(v: AutocompleteItem<Warehouse>) => {
                  onSelectProductWarehouse(v);
                }}
              />
              <ShareRangeDateSelect
                inputClassName="range-date"
                containerClassName="range-data-wrapper"
                placeholder={['Từ ngày', 'Đến ngày']}
                format={'yyyy-MM-DD'}
                onChange={(date, dateString) => {
                  onProductDateChange(dateString);
                }}
                // defaultValue={[DEFAULT_FROM_DATE, DEFAULT_TO_DATE]}
              />
              <ShareInput
                containerClassName="filter-wrapper"
                placeholder="Tìm kiếm"
                className="search-rules__input"
                type="text"
                onChange={(e: any) => {
                  onProductSearch(e.target.value);
                }}
                prevIcon={<SearchIcon />}
              />
            </div>
          </div>
          <SharedTable
            onRowClick={(item: ProductStatisticColumn) => {
              getProductItemStatistic(item.product_id, item.product_name);
            }}
            columns={productColumns}
            dataSource={productStatistic.data}
          />
          <div className="bottom-pagination">
            <Pagination
              className="pagination-bar"
              currentPage={payloads.product.page}
              totalCount={productStatistic.count}
              pageSize={productStatistic.limit}
              onPageChange={(page: number) => {
                onProductPageChange(page);
              }}
            />
          </div>
        </>
      ),
    },
  ];
  LogApp(customerPaymentData, 'customerPaymentData');
  const { theme } = useTheme();
  return (
    <StyledDashboard>
      <div className="dashboard-contain">{/* <OverviewModule dashboardPayload={payload} /> */}</div>
      <div className="grid grid-cols-12 gap-6">
        {/* <div className="box col-span-12 lg:col-span-6 mt-8">
          <div>
            <ActiveMemberChartModule dashboardPayload={payload} />
          </div>
        </div>
        <div
          className="box col-span-12 sm:col-span-6 lg:col-span-6 mt-8 pr-10"
          id="tiers-chart_contain"
        >
          <h4>Member tier</h4>
          <TierChartModule />
        </div> */}
        <div className="box col-span-12 ">
          <Tabs
            onChange={(key) => {
              onTabChange(key);
            }}
            defaultActiveKey="1"
            type="card"
            items={TABS}
          />
        </div>
      </div>
      <AppModal
        open={opened}
        onClose={() => {
          onModalInteract(false);
        }}
      >
        <form onSubmit={handlePayment} className="inner-modal relative">
          <h2 className="title">Chi tiết</h2>
          <div className="money-block">
            <span>
              Tổng tiền: <span className="money">{customerPaymentData.total_money}</span>
            </span>
            <span>
              Tổng tiền nợ: <span className="money">{customerPaymentData.total_money_debt}</span>
            </span>
            <span>
              Tổng tiền đã trả:{' '}
              <span className="money">{customerPaymentData.total_money_paid}</span>
            </span>
          </div>
          <div className="payment-block">
            <ShareInput
              prefix="đ"
              inputDefaultStyle="preTab"
              isNumericFormat
              control={control}
              containerClassName="inp-wrapper"
              name="money"
              label="Khách thanh toán"
              errors={errors['money']?.message}
            />
            <SharedButton
              typeHtml="submit"
              className={`submit-btn ${errors['money']?.message ? 'self-center' : 'self-end'}`}
              backgroundColor={MAIN_THEME_DATA.mainColor}
              textColor="white"
              text="Thanh toán"
            />
          </div>
          <SharedTable columns={debtOrderColumns} dataSource={customerPaymentData.data} />
          <div className="close-wrapper">
            {/* <SharedButton
              onClick={() => {
                onModalInteract(false);
              }}
              className="close-btn"
              backgroundColor={'transparent'}
              text="Đóng"
              textColor={theme?.colors?.button?.text}
              borderColor={theme?.colors?.button?.border}
            /> */}
            <IConCancel
              className="absolute right-0 top-0"
              onClick={() => {
                onModalInteract(false);
              }}
            />
          </div>
        </form>
      </AppModal>
      <AppModal
        open={productModal.opened}
        onClose={() => {
          // onProductModalInteract(false);
        }}
      >
        <div className="inner-modal">
          <h2 className="title">{productModal.productName}</h2>
          <SharedTable columns={productItemColumns} dataSource={productItemData} />
          <div className="close-wrapper">
            <SharedButton
              onClick={() => {
                onProductModalInteract(false);
              }}
              className="close-btn"
              backgroundColor={'transparent'}
              text="Đóng"
              textColor={theme?.colors?.button?.text}
              borderColor={theme?.colors?.button?.border}
            />
          </div>
        </div>
      </AppModal>
    </StyledDashboard>
  );
};

export const StyledDashboard = styled.div`
  .app-modal {
    .content {
      height: 70rem;
      overflow-y: auto;
    }
  }
  .ant-tabs-tab:hover {
    color: ${MAIN_THEME_DATA.mainColor};
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
    background: ${MAIN_THEME_DATA.mainColor};
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
  }
  .bottom-pagination {
    margin-top: 3.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .page-size {
      display: flex;
      align-items: center;
      .label {
        display: inline-block;
        white-space: nowrap;
        padding-right: 0.8rem;
      }
    }
  }
  .inner-modal {
    width: 100rem;
    .money-block {
      display: flex;
      flex-direction: column;
      .money {
        font-size: 1.7rem;
        font-weight: 500;
        color: ${MAIN_THEME_DATA};
      }
    }
    .close-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
      .close-btn {
        padding: 1rem 3.4rem;
      }
    }

    h2 {
      text-align: center;
      margin-bottom: 1rem;
    }
    .payment-block {
      display: flex;
      width: 50%;
      margin-bottom: 2rem;
      .inp-wrapper {
        width: 70%;
      }
      .submit-btn {
        padding: 0.8rem 1.2rem;
        height: fit-content;
        margin-left: 2rem;
      }
    }
  }
  .filters {
    .main-filters {
      .time-select {
        width: fit-content;
        min-width: 12rem;
      }
      .store-select {
        width: fit-content;
        min-width: 12rem;
        max-width: 35rem;
      }
      .branch-group-select {
        width: fit-content;
        max-width: 20rem;
      }
    }
  }
  .box {
    /* margin-top: 4rem; */
    border-radius: 2rem;
    padding: 2rem;
    background: ${(p: any) => p?.theme.colors?.bgSection};
    h4 {
    }
  }
  .head__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2rem;
    .ant-select {
      max-width: 15rem;
      .ant-select-selector {
        height: 4.5rem;
      }
    }
  }
  .debt-filter {
    display: flex;
    .filter-wrapper {
      width: 22rem;
      margin-left: 1rem;
    }
    .range-data-wrapper {
      width: 30rem;
      margin-left: 1rem;
    }
    .range-date {
      height: 3.8rem;
    }
  }
`;
