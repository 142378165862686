import { ChangeBannerStatusPayload } from '@interfaces';
import { ApiClient, DefaultApiParams } from './axiosClient';

export const bannerAPI = {
  getBanners: (params?: DefaultApiParams) => {
    const url = '/images/banners?size=10';
    return ApiClient.get(url, { params });
  },
  uploadBanners: (payload: FormData) => {
    const url = '/images/banner-upload';
    return ApiClient.post(url, payload);
  },
  changeBannerStatus: (payload: ChangeBannerStatusPayload) => {
    const url = '/images/banner-disable';
    return ApiClient.post(url, payload);
  },
};
