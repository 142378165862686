import { DefaultApiParams, categoriesAPI, productAPI, promotionAPI, warehouseAPI } from '@api';
import { HideIcon, PopoverPopup, Promotion, SharedButton, TickBox, ViewIcon } from '@components';
import {
  DEFAULT_API_PARAMS,
  DEFAULT_CATEGORY_FILTER_ITEM,
  DEFAULT_WAREHOUSE_FILTER_ITEM,
  INITIAL_PAGE,
  MAIN_THEME_DATA,
  MAX_ITEM_PER_PAGE,
  PATH_CREATE_PROMOTION,
  PATH_PROMOTION,
} from '@configs';
import {
  CategoryListRoot,
  Product,
  ProductFilterDropdowns,
  ProductResponse,
  ProductRoot,
  PromotionColumn,
  PromotionData,
  PromotionResponse,
  PromotionalProduct,
  PromotionalProductPayload,
  PromotionalProductResponse,
  RootResponse,
  WarehouseRoot,
} from '@interfaces';
import {
  selectApp,
  setCurrentPage,
  setLoading,
  setTableLoading,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { LogApp } from '@utils';
import { ColumnsType } from 'antd/lib/table';
import { debounce } from 'lodash';
import moment from 'moment';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useImmer } from 'use-immer';

export const PromotionModule = () => {
  const { themeMode, currentPage } = useAppSelector(selectApp);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const onNavigateToCreatePromotion = () => {
    navigate(PATH_CREATE_PROMOTION);
  };
  const [promotionalProducts, setPromotionalProducts] = useImmer<PromotionalProductResponse>({
    content: [],
    amount: 0,
  });
  const [payload, setPayload] = useState<DefaultApiParams>(DEFAULT_API_PARAMS);
  const [page, setPage] = useState(0);
  const [promotionalProductPage, setPromotionalProductPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [dropdownData, setDropdownData] = useImmer<ProductFilterDropdowns>({
    warehouses: [],
    categories: [],
  });
  const [productData, setProductData] = useImmer<ProductResponse>({
    products: [],
    amount: 0,
    total_amount_remain: 0,
  });
  const [promotionPayload, setPromotionPayload] = useState<DefaultApiParams>({
    page: currentPage || INITIAL_PAGE,
    keyword: '',
    size: 10,
  });
  const [modalData, setModalData] = useState({
    open: false,
    id: '',
  });
  const [promotions, setPromotions] = useImmer<PromotionData>({
    limit: 0,
    count: 0,
    data: [],
  });
  const columns: ColumnsType<PromotionColumn> = useMemo(() => {
    return [
      {
        title: 'STT',
        dataIndex: 'no',
        key: 'no',
        render: (text, record, index) => index + currentPage * Number(promotionPayload?.size) + 1,
        width: '3%',
      },
      { title: 'Mã', dataIndex: 'code', key: 'code' },
      {
        title: 'Tiêu đề',
        dataIndex: 'name',
        key: 'name',
        width: '25%',
      },
      { title: 'Thời gian', dataIndex: 'time', key: 'time' },
      // {
      //   title: 'Phần trăm',
      //   dataIndex: 'discount_percent',
      //   key: 'discount_percent',
      //   width: '10%',
      // },
      {
        title: 'Hành động',
        dataIndex: '',
        key: 'x',
        render: (value: any) => {
          return (
            <StyledEditBtn>
              <SharedButton
                textColor="white"
                // backgroundColor={MAIN_THEME_DATA.mainColor}
                className="edit-btn btn-edit"
                prevIcon={<TickBox color="white" />}
                text="Danh sách sản phẩm"
                onClick={() => {
                  value.showProductList();
                }}
              />
              <SharedButton
                textColor="white"
                // backgroundColor={MAIN_THEME_DATA.mainColor}
                className="edit-btn btn-info mx-3"
                prevIcon={<TickBox color="white" />}
                text="Chỉnh sửa"
                onClick={() => {
                  value.onEdit();
                }}
              />
              <PopoverPopup
                content={
                  <StyledConfirmPopup>
                    <h5 className="text-center items-center mb-2 text-current text-base">
                      Thông báo
                    </h5>
                    <p className="text-sm">
                      Bạn có muốn {value.activated ? 'kết thúc' : 'kích hoạt'} khuyến mãi này không?
                    </p>
                  </StyledConfirmPopup>
                }
                isHaveConfirmButton
                onConfirm={() => {
                  value.onActivate();
                }}
              >
                <SharedButton
                  className={`edit-btn ${value.activated ? 'btn-delete' : 'btn-edit'}`}
                  backgroundColor={MAIN_THEME_DATA.mainColor}
                  prevIcon={
                    value.activated ? <HideIcon color="white" /> : <ViewIcon color="white" />
                  }
                  textColor="white"
                  text={value.activated ? 'Kết thúc' : 'Kích hoạt'}
                />
              </PopoverPopup>
            </StyledEditBtn>
          );
        },
      },
    ];
  }, []);
  const loadMoreData = (key: 'promotion' | 'product') => {
    if (key === 'product') setPayload({ ...payload, page: page + 1 });
    else {
      setPromotionalProductPage((prv) => prv + 1);
    }
  };
  const onSearch = debounce((value: string) => {
    setPayload({ ...payload, keyword: value, page: 0 });
  }, 600);
  const onSearchPromotion = debounce((value: string) => {
    setPromotionPayload({ ...payload, keyword: value, page: 0 });
  }, 600);
  const onChangeWarehouse = (id: string) => {
    if (id === 'null') {
      const clonedPayload = { ...payload, page: 0 };
      delete clonedPayload.warehouse_id;
      setPayload(clonedPayload);
    } else setPayload({ ...payload, warehouse_id: id, page: 0 });
  };
  const onChangeCategory = (id: string) => {
    if (id === 'null') {
      const clonedPayload = { ...payload, page: 0 };
      delete clonedPayload.category_id;
      setPayload(clonedPayload);
    } else setPayload({ ...payload, category_id: id, page: 0 });
  };
  const getDropdownData = async () => {
    try {
      const warehouses: WarehouseRoot = await warehouseAPI.getWarehouses({ all: true });
      const categories: CategoryListRoot = await categoriesAPI.getCategories({ all: true });
      setDropdownData((draft) => {
        draft.warehouses = warehouses.data.warehouse_response.map((item) => {
          return {
            label: item.name,
            value: item.id,
            item,
          };
        });
        draft.warehouses = [
          { label: 'Tất cả', value: 'null', item: DEFAULT_WAREHOUSE_FILTER_ITEM },
          ...draft.warehouses,
        ];
        draft.categories = categories.data.categories.map((item) => {
          return {
            label: item.name,
            value: item.id,
            item,
          };
        });
        draft.categories = [
          { label: 'Tất cả', value: 'null', item: DEFAULT_CATEGORY_FILTER_ITEM },
          ...draft.categories,
        ];
      });
    } catch (error) {
      LogApp(error, 'err');
    }
  };
  const getProducts = async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      const res: ProductRoot = await productAPI.getProducts({ ...payload });
      const products = res.data.products;
      setProductData((draft) => {
        draft.amount = res.data.amount;
        if (payload.page === 0) {
          LogApp('ndhrun2');
          draft.products = products.map((item) => {
            return {
              ...item,
              isSelected: false,
            };
          });
        } else {
          LogApp('ndhrun3');
          draft.products = draft.products.concat(products).map((item) => {
            return {
              ...item,
              isSelected: false,
            };
          });
        }
      });
    } catch (error) {
      LogApp(error, 'err');
    } finally {
      setIsLoading(false);
    }
  };
  const getPromotionalProducts = async (id: string) => {
    try {
      const res: RootResponse<PromotionalProductResponse> =
        await promotionAPI.getPromotionalProducts(id, { page: promotionalProductPage });
      const products = res.data.content;
      setPromotionalProducts((draft) => {
        draft.amount = res.data.amount;
        if (promotionalProductPage === 0) {
          draft.content = products;
        } else {
          draft.content = draft.content.concat(products);
        }
      });
    } catch (error) {}
  };
  const showProductList = async (id: string) => {
    try {
      dispatch(setLoading(true));
      await getDropdownData();
      await getPromotionalProducts(id);
      setModalData({
        id,
        open: true,
      });
    } catch (error) {
      LogApp(error, 'err');
    } finally {
      dispatch(setLoading(false));
    }
  };
  const getPromotions = async () => {
    try {
      dispatch(setTableLoading(true));
      setPromotions({ ...promotions, data: [] });
      const res: RootResponse<PromotionResponse> = await promotionAPI.getPromotions(
        promotionPayload,
      );
      const newResponse: PromotionColumn[] = res.data.content.map((item, index) => {
        return {
          no: index + 1,
          key: item.id,
          name: item.name,
          code: item.code,
          time: `${moment(item.start_day).format('DD/MM/YYYY')} - ${moment(item.end_day).format(
            'DD/MM/YYYY',
          )}`,
          discount_percent: item.discount_percent,
          showProductList: () => {
            showProductList(item.id);
          },
          onActivate: () => {
            onActivatePromotion(item.id, !item.activated);
          },
          onEdit: () => {
            navigate(PATH_PROMOTION + `/edit/${item.id}`);
          },
          activated: item.activated,
        };
      });
      setPromotions({
        limit: MAX_ITEM_PER_PAGE,
        count: res.data.amount,
        data: newResponse,
      });
    } catch (error) {
      LogApp(error, 'err');
    } finally {
      dispatch(setTableLoading(false));
    }
  };
  const addPromotionalProducts = async () => {
    if (promotionalProducts.content.length === 0) {
      toast.error(`Vui lòng chọn ít nhất 1 sản phẩm`, {
        position: 'top-right',
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'colored',
      });
      return;
    }
    try {
      dispatch(setLoading(true));
      const payload: PromotionalProductPayload = {
        product_ids: promotionalProducts.content.map((item) => item.product_id),
        promotion_id: modalData.id,
      };
      const res = await promotionAPI.addPromotionalProducts(payload);
      toast.success(`Thành công`, {
        position: 'top-right',
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'colored',
      });
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
      setModalData({ ...modalData, open: false });
    }
  };
  const onPageChange = (num: number) => {
    dispatch(setCurrentPage(num));
    setPromotionPayload({ ...promotionPayload, page: num });
  };
  const onSelectProduct = (item: Product) => {
    setPromotionalProducts((d) => {
      const promotionalItem: PromotionalProduct = {
        product_id: item.id,
        product_name: item.name,
        promotion_id: modalData.id,
        image: item.images.at(0),
        warehouse_name: item.warehouse.name,
      };
      d.content.push(promotionalItem);
      d.amount++;
    });
  };
  const onRemovePromotionalProduct = async (item: PromotionalProduct) => {
    try {
      dispatch(setLoading(true));
      const foundIndex = promotionalProducts.content.findIndex(
        (val) => val.product_id === item.product_id,
      );
      if (foundIndex !== -1) {
        const res = await promotionAPI.deletePromotionalProducts(modalData.id, item.product_id);
        setPromotionalProducts((d) => {
          d.content.splice(foundIndex, 1);
          d.amount--;
        });
        toast.success(`Thành công`, {
          position: 'top-right',
          autoClose: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          theme: 'colored',
        });
      }
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
  const onActivatePromotion = async (id: string, value: boolean) => {
    try {
      dispatch(setLoading(true));
      const res = await promotionAPI.activatePromotion(id, value);
      toast.success(`Thành công`, {
        position: 'top-right',
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'colored',
      });
      await getPromotions();
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
  const onClose = () => {
    setModalData({ ...modalData, open: false });
    setPayload(DEFAULT_API_PARAMS);
  };
  const onUploadFile = async () => {
    try {
      dispatch(setLoading(true));
      const payload = new FormData();
      payload.append('file', selectedFile as any);
      const res = await promotionAPI.importPromotionProduct(modalData.id, payload);
      setModalData({ ...modalData, open: false });
      setSelectedFile(null);
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
  const onChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(event.target.files?.[0] as any);
  };
  const onPickProduct = (index: number) => {
    setProductData((draft) => {
      draft.products[index].isSelected = !draft.products[index].isSelected;
    });
  };
  const onSelectAllProduct = () => {
    setProductData((draft) => {
      draft.products = draft.products.map((item) => {
        return {
          ...item,
          isSelected: true,
        };
      });
    });
  };
  const onAddAllProduct = () => {
    const promotionItems = productData.products
      .filter((item) => item.isSelected)
      .map((item) => {
        return {
          product_id: item.id,
          product_name: item.name,
          promotion_id: modalData.id,
          image: item.images.at(0),
          warehouse_name: item.warehouse.name,
        };
      });
    setPromotionalProducts((d) => {
      d.content = d.content.concat(promotionItems);
      d.amount += promotionItems.length;
    });
    if (promotionItems.length >= MAX_ITEM_PER_PAGE) loadMoreData('product');
  };
  useEffect(() => {
    getPromotions();
  }, [promotionPayload.keyword]);
  useEffect(() => {
    if (modalData.open) getProducts();
  }, [modalData.open, payload.page, payload.keyword, payload.category_id, payload.warehouse_id]);
  return (
    <Promotion
      columns={columns}
      onNavigateToCreatePromotion={onNavigateToCreatePromotion}
      productFilterData={dropdownData}
      onSearch={onSearch}
      onSearchPromotion={onSearchPromotion}
      onChangeWarehouse={onChangeWarehouse}
      onChangeCategory={onChangeCategory}
      payload={payload}
      productData={productData}
      loadMoreData={loadMoreData}
      promotions={promotions}
      currentPage={promotionPayload.page}
      onPageChange={onPageChange}
      modalVisible={modalData.open}
      onConfirm={addPromotionalProducts}
      onClose={onClose}
      onSelectProduct={onSelectProduct}
      promotionalProducts={promotionalProducts}
      onRemovePromotionalProduct={onRemovePromotionalProduct}
      onChangeFile={onChangeFile}
      onUploadFile={onUploadFile}
      selectedFile={selectedFile}
      onSelectAllProduct={onSelectAllProduct}
      onAddAllProduct={onAddAllProduct}
      onPickProduct={onPickProduct}
    />
  );
};
const StyledEditBtn = styled.div`
  display: flex;
  justify-content: center;
  .edit-btn {
    padding: 0.8rem 1.8rem;
    width: fit-content;
    align-self: center;
  }
`;
const StyledConfirmPopup = styled.div`
  .btn {
    padding: 0.4rem 0.3rem;
    /* max-width: 5rem; */
  }
`;
